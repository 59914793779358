import Button from '@/components/button';
import InputControl from '@/components/form/input-control';
import Text from '@/components/text';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useToast from '@/hooks/use-toast.hook';
import { OrgModel } from '@/lib/models/org.model';
import { patchOrg } from '@/lib/services/org.service';
import { Box, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { FileWithPath } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import { Form } from '@/components/form';
import SurepathLogomarkCarbon from '@/assets/logomark-carbon-0924.svg';

export type FileWithPreview = FileWithPath & { preview: string };

interface Props {
  organization: OrgModel;
  readonly: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  portalBadgeForm: {
    width: '50%',
    boxSizing: 'border-box',
    marginRight: 100,
    paddingRight: 16,
  },
  imagePreview: {
    '& img': {
      height: 40,
      width: 40,
    },
  },
  userBadgePreview: {
    '& .MuiAvatar-root': {
      backgroundColor: '#7ebd94',
    },
  },
  titleForm: {
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  portalAssistantPreview: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 16,
    borderRadius: theme.shape.borderRadius,
  },
}));

const MAX_TITLE_LENGTH = 30;

const PortalTitleForm: FC<Props> = ({ organization, readonly }) => {
  const styles = useStyles();
  const { portalBadge = { image: '', title: 'Assistant' } } = organization.branding;

  const { toast, errorToast } = useToast();

  const formHandle = useFormHandle(
    {
      initialValues: { title: portalBadge.title || 'Assistant' },
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        const updatedOrg = new OrgModel(organization._props);
        updatedOrg.branding.portalBadge.title = values.title;

        const updated = await patchOrg(updatedOrg);

        setSubmitting(false);

        if (updated) {
          resetForm({ values });
          toast('The assistant title was updated');
          return;
        }

        errorToast('The assistant title was not updated');
      },
    },
    { dirtyLockDisabled: true }
  );

  const { values, canSubmit, canCancel, handleSubmit, resetForm } = formHandle;
  const hasImage = !!portalBadge.image;

  return (
    <>
      <Box mb={4}>
        The Portal Assistant Title appears next to assistant responses in the Portal, along with the
        assistant's badge.
      </Box>

      <Stack direction="row" gap={6} mt={5} width="100%">
        {!readonly && (
          <Box width="50%">
            <Stack gap={2}>
              <Box>
                <Text bold>Update Assistant Title</Text>
              </Box>
              <Form formHandle={formHandle}>
                <Box>
                  <InputControl
                    name="title"
                    label="Assistant Title"
                    disabled={readonly}
                    maxLength={MAX_TITLE_LENGTH}
                    formHandle={formHandle}
                  />
                </Box>
                <Stack direction="row" gap={1} justifyContent="flex-end">
                  <Button
                    label="Save"
                    disabled={!canSubmit}
                    type="submit"
                    size="small"
                    onClick={handleSubmit}
                  />
                  <Button
                    label="Cancel"
                    disabled={!canCancel}
                    onClick={resetForm}
                    type="cancel"
                    size="small"
                  />
                </Stack>
              </Form>
            </Stack>
          </Box>
        )}

        <Box width="50%">
          <Stack gap={2}>
            <Box>
              <Text bold>Badge and Title Preview</Text>
            </Box>
            <Box>
              <Text size="small">
                Below is a preview of the Portal Assistant profile, including a combination of the
                badge image and title
              </Text>
            </Box>

            <Box mt={2} gap={2} className={styles.portalAssistantPreview}>
              <Stack direction="row" gap={2}>
                <Box>
                  {hasImage && (
                    <Box className={styles.imagePreview}>
                      <img src={portalBadge.image} />
                    </Box>
                  )}
                  {!hasImage && (
                    <Box className={styles.userBadgePreview}>
                      <img src={SurepathLogomarkCarbon} width={40} alt={values.title} />
                    </Box>
                  )}
                </Box>
                <Stack gap={2}>
                  <Text bold>{values.title}</Text>
                  <Box>Welcome to the Portal. What would you like to get done today?</Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default PortalTitleForm;
