import { ReactNode, FC, useState, useEffect } from 'react';
import {
  AuthUser,
  getAssumeFlag,
  getAuthNoAccessUrl,
  getAuthRedirectUrl,
  getCurrentUser,
  getFromAuthSite,
  syncAndRefreshTokens,
  signOut,
  userCan,
} from '../../lib/services/auth.service';
import { BreadCrumb } from '@/components/app-header/helpers';
import { AppContext, AppSettings } from './helpers';
import { setDatadogUser } from '@/lib/datadog';
import { LoadState } from '@/lib/helpers';
import { prefetchAppData } from '@/lib/app-data';
import { getUrlQueryParam } from '@/lib/url-helpers';

interface Props {
  children: ReactNode;
}

const isPublicPath = (path: string): boolean => ['/error', '/logout'].includes(path);

export const AppProvider: FC<Props> = ({ children }) => {
  // @todo consistent org settings load?
  const handleSignIn = (authUser: AuthUser): boolean => {
    setSettings((old) => ({
      ...old,
      user: authUser,
      signedIn: true,
    }));

    return true;
  };

  const [settings, setSettings] = useState<AppSettings>({
    loadState: 'unloaded',
    pageLoadState: 'unloaded',
    signedIn: false,
    signIn: handleSignIn,
    signOut,
    user: null,
    pageTitle: { plain: '' },
    setPageTitle: (plain: string, breadcrumb?: BreadCrumb | BreadCrumb[]) => {
      setSettings((old) => ({
        ...old,
        pageTitle: { plain, breadcrumb },
      }));
    },
    setPageLoadState: (loadState: LoadState) => {
      setSettings((old) => ({
        ...old,
        pageLoadState: loadState,
      }));
    },
    isDemo: !!getUrlQueryParam('demo'),
  });

  // run once on boot
  useEffect(() => {
    const { loadState } = settings;
    if (loadState !== 'unloaded') {
      return;
    }

    setSettings((old) => ({
      ...old,
      loadState: 'loading',
    }));
  }, [settings]);

  // check for auth and load user and org
  useEffect(() => {
    const { loadState } = settings;
    if (loadState !== 'loading') {
      return;
    }

    if (isPublicPath(window.location.pathname)) {
      setSettings((old) => ({
        ...old,
        user: null,
        signedIn: false,
        loadState: 'loaded',
      }));

      return;
    }

    const fromAuthSite = getFromAuthSite(); // was the user just redirected from auth? remove the qs param, making it "one-time use"
    const assumeFlag = getAssumeFlag(); // is the user attempting to assume a role? skip checking firebase ls for a login

    getCurrentUser(assumeFlag).then((user) => {
      const signedIn = !!user;

      /*
       * This auth check is a duplicate of the router-defined `beforeLoad: requireAuth` check. The
       * reason for the unfortunate duplication of effort here is because I couldn't find a good way
       * to redirect to an external URL from a @tanstack/react-router beforeLoad function, or any other
       * route-guard-type function. Using a 'window.location.href' redirect method would still cause the
       * page to load briefly, which is not great UX.
       */
      if (!signedIn) {
        window.location.href = getAuthRedirectUrl(fromAuthSite);
        return;
      }

      // not an org auditor or better? redirect to not-authorized page
      if (!userCan(user, 'read')) {
        window.location.href = getAuthNoAccessUrl();
        return;
      }

      syncAndRefreshTokens();

      setDatadogUser(user.uid, user.email || '');

      setSettings((old) => ({
        ...old,
        user,
        signedIn,
        loadState: 'loaded',
      }));

      prefetchAppData();
    });
  }, [settings]);

  return <AppContext.Provider value={settings}>{children}</AppContext.Provider>;
};
