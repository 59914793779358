import { post } from './sp-api.service';

const LINK_WORKOS_PATH = '/directory-sync/provision ';

export const connectWorkOS = async ({
  name,
  domains,
}: {
  name: string;
  domains: string[];
}): Promise<string> => {
  const response = await post(LINK_WORKOS_PATH, { name, domains });
  return (response?.workosPortalLink as string) || '';
};
