import { FC } from 'react';
import { useQuery } from 'react-query';
import { deletePolicy, getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import FormButtons from '@/components/form/form-buttons';
import useQueryHelper from '@/hooks/use-query-helper';
import { useNavigate } from '@tanstack/react-router';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['name', 'description'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const PolicyProfileForm: FC<Props> = ({ policyId }) => {
  const { errorToast, toast } = useToast();
  const navigate = useNavigate();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(policyId, values.propSlice(fields));
      setSubmitting(false);

      if (updated) {
        resetForm({ values });
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const { values } = formHandle;

  const query = useQuery([QueryKey.PolicyView, policyId], async () => getPolicyById(policyId));
  const { showLoader } = useQueryHelper(query);

  const handleDelete = () => {
    deletePolicy(policyId).then(({ deleted, error }) => {
      if (deleted) {
        toast('The policy was deleted');
        navigate({ to: '/config/group-policies' });
        return;
      }

      errorToast(error || 'The policy was not deleted');
    });
  };

  if (showLoader) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Profile Settings">
        <InputControl
          name="name"
          label="Name"
          formHandle={formHandle}
          readonly={!canChangePolicy}
        />

        <InputControl
          name="description"
          label="Description"
          formHandle={formHandle}
          readonly={!canChangePolicy}
          type="textarea"
        />
      </FormSection>

      <FormButtons
        formHandle={formHandle}
        onDelete={handleDelete}
        deleteName={values.name}
        readonly={!canChangePolicy}
      />
    </Form>
  );
};

export default PolicyProfileForm;
