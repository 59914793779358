import { CreateResponse, DeleteResponse, FieldError, PatchResponse } from '.';
import { JsonObject } from '../helpers';
import { getTypedIdentProvider } from '../models/ident-provider';
import {
  CreateIdentProviderModel,
  IdentProviderModel,
} from '../models/ident-provider/ident-provider.model';
import { deleteApi, get, patch, post } from './sp-api.service';
import { AuthProvider } from './auth.service';
import { AxiosError } from 'axios';
import { SamlMetadataUrlIdentConfig } from '../models/ident-provider/saml-metadata-url-ident-provider.model';
import { SamlIdentConfig } from '../models/ident-provider/saml-ident-provider.model';
import { SamlMetadataXmlIdentConfig } from '../models/ident-provider/saml-metadata-xml-ident-provider.model';
import { sortBy } from 'lodash';

export const MAX_PROVIDER_COUNT = 5;

export const getAllIdentProviders = async (): Promise<IdentProviderModel[]> => {
  const response = (await get(`/auth/identity/${AuthProvider.orgId}/providers/`)) as JsonObject[];

  if (!response?.length) {
    return [];
  }

  const providers = response.map((data) => new IdentProviderModel(data)).map(getTypedIdentProvider);

  return sortBy(providers, [
    ({ type }) => type !== 'magicLink',
    ({ name }) => String(name).toLowerCase(),
  ]);
};

export const getIdentProviderById = async (id: string): Promise<IdentProviderModel | null> => {
  const response = (await get(
    `/auth/identity/${AuthProvider.orgId}/providers/${id}`
  )) as JsonObject;
  return response ? new IdentProviderModel(response) : null;
};

export const createIdentProvider = async (
  provider: IdentProviderModel
): Promise<CreateResponse<IdentProviderModel>> => {
  const { name, type, createType, identityProviderConfig } = provider;

  const response = await post(`/auth/identity/${AuthProvider.orgId}/providers/`, {
    name,
    type: createType,
  });

  const newModelId = response?.id as string;

  if (!response || !newModelId) {
    return createErrorHandler(response);
  }

  let newIdentProvider = new IdentProviderModel(response);

  if (type === 'SAML-URL') {
    const samlMetadataUrl = (identityProviderConfig as SamlMetadataUrlIdentConfig).samlMetadataUrl;
    const patchData = { identityProviderConfig: { samlMetadataUrl } as unknown as SamlIdentConfig };

    const { patched, error, data } = await patchIdentProvider(newModelId, patchData);

    if (!patched) {
      await deleteIdentProvider(newModelId);
      return {
        created: false,
        error,
      };
    }

    newIdentProvider = new IdentProviderModel(data);
  }

  if (type === 'SAML-XML') {
    const rawXml = (identityProviderConfig as SamlMetadataXmlIdentConfig).samlMetadataXML;

    const patchData = {
      identityProviderConfig: { samlMetadataXML: rawXml } as unknown as SamlIdentConfig,
    };

    const { patched, error, data } = await patchIdentProvider(newModelId, patchData);

    if (!patched) {
      await deleteIdentProvider(newModelId);
      return {
        created: false,
        error,
      };
    }

    newIdentProvider = new IdentProviderModel(data);
  }

  return {
    created: true,
    model: getTypedIdentProvider(newIdentProvider),
  };
};

export const patchIdentProvider = async (
  id: string,
  data: Partial<CreateIdentProviderModel>
): Promise<PatchResponse> => {
  return patch(`/auth/identity/${AuthProvider.orgId}/providers/${id}`, data, patchErrorHandler);
};

export const deleteIdentProvider = async (id: string): Promise<DeleteResponse> => {
  return deleteApi(`/auth/identity/${AuthProvider.orgId}/providers/${id}`);
};

const patchErrorHandler = (error: AxiosError): PatchResponse => {
  const response = error.response?.data as JsonObject;

  return {
    patched: false,
    error: response.status as string,
  };
};

const createErrorHandler = (response: JsonObject | null): CreateResponse<IdentProviderModel> => {
  return {
    created: false,
    fieldErrors: Array.isArray(response) ? (response as FieldError[]) : [],
  };
};
