import { FC, useEffect, useState } from 'react';
import { Outlet } from '@tanstack/react-router';
import AppSidebar from './components/app-sidebar';
import { Box, Stack, Theme, Toolbar } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useDocumentTitle } from 'usehooks-ts';
import AppHeader from './components/app-header';
import useApp from './hooks/use-app.hook';
import ModalRoot from './contexts/modal/modal-root';
import Text from './components/text';
import { useIsFetching } from 'react-query';
import usePrior from './hooks/use-prior.hook';
import AssumeRoleInlineToast from './components/toasts/assume-role/inline';
import useAppData from './hooks/use-app-data.hook';
import { OrgBasicInfo } from './lib/models/org.model';
import { AppDataQueryKey } from './lib/query-client';
import { UserControlProvider } from './contexts/user-control';

const SIDEBAR_WIDTH = 240;

const useStyles = createUseStyles<string, { signedIn: boolean; assumeActive: boolean }>(
  (theme: Theme) => ({
    main: {
      flexGrow: 1,
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
      backgroundColor: ({ signedIn }) =>
        signedIn ? theme.palette.grey[200] : theme.palette.common.white,
    },
    containerScroll: ({ assumeActive }) => ({
      width: '100%',
      // account for app-header height, and assume-role banner height. If you changes those, you MUST change this
      height: assumeActive ? 'calc(100% - 124px)' : 'calc(100% - 64px)',
      overflowY: 'auto',
      paddingTop: 20,
    }),
    container: ({ signedIn }) => ({
      width: '100%',
      minHeight: signedIn ? '100%' : 'inherit',
      height: signedIn ? '100%' : 'inherit',
      padding: '0px 24px',
      boxSizing: 'border-box',
      margin: '0 auto',
    }),
    '@global': {
      body: {
        overflowY: 'hidden',
      },
      input: {
        '&:-webkit-autofill': {
          background: 'none',
          backgroundColor: 'transparent !important',
          backgroundClip: 'text',
        },
      },
      a: {
        color: theme.palette.link.main,
        textDecoration: 'none',
        '&:hover': {
          opacity: 0.7,
        },
        '&.flat': {
          color: theme.palette.common.black,
        },
      },
      '& .notistack-SnackbarContainer': {
        '& .notistack-MuiContent.notistack-MuiContent-info': {
          backgroundColor: theme.palette.info.main,
        },
        '& .notistack-MuiContent.notistack-MuiContent-warning': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
  })
);

const App: FC = () => {
  const [open, setOpen] = useState(true);
  const handleSidebarToggleOpen = () => {
    setOpen(!open);
  };
  const { signedIn, pageTitle, setPageLoadState, user } = useApp();

  const { plain } = pageTitle;
  const hasPageTitle = Boolean(plain);

  const { data: orgBasicInfo } = useAppData<OrgBasicInfo>(AppDataQueryKey.OrgBasicInfo, {
    enabled: signedIn,
  });
  const { assumed, role } = user || {};
  const assumeActive = !!assumed;

  const styles = useStyles({ signedIn, assumeActive });

  useDocumentTitle(`SurePath AI ${hasPageTitle ? '| ' : ''} ${plain}`);

  const isFetching = useIsFetching();
  const priorIsFetching = usePrior(isFetching);

  useEffect(() => {
    if (isFetching && !priorIsFetching) {
      setPageLoadState('loading');
      return;
    }

    if (!isFetching && priorIsFetching) {
      setTimeout(() => {
        setPageLoadState('unloaded');
      }, 300);
      return;
    }
  }, [isFetching, priorIsFetching, setPageLoadState]);

  return (
    <UserControlProvider>
      {assumeActive && <AssumeRoleInlineToast role={role!} orgName={orgBasicInfo?.name || ''} />}
      <Stack direction="row">
        <AppHeader
          open={open}
          onToggleOpen={handleSidebarToggleOpen}
          width={SIDEBAR_WIDTH}
          signedIn={signedIn}
          hasBanner={assumeActive}
        />
        {signedIn && (
          <AppSidebar open={open} onToggleOpen={handleSidebarToggleOpen} width={SIDEBAR_WIDTH} />
        )}
        <Box
          component="main"
          className={styles.main}
          display="flex"
          flexDirection="column"
          alignItems={signedIn ? 'flex-start' : 'center'}
        >
          <Toolbar />
          <Box className={styles.containerScroll}>
            <Box className={styles.container} maxWidth="xl">
              {!signedIn && hasPageTitle && (
                <Stack alignItems="center">
                  <Text size="x-large" noWrap>
                    {plain}
                  </Text>
                </Stack>
              )}
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Stack>
      <ModalRoot />
    </UserControlProvider>
  );
};

export default App;
