import { DEFAULT_PAGE_SIZE, Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import { AdminUserModel, CreateAdminUserModel } from '../models/admin-user.model';
import {
  deleteOne,
  findAllAndCount,
  findOne,
  getOrgFilter,
  getSort,
  insertOne,
  patchOne,
} from './atlas-data-api.service';
import { DeleteResponse, UpdateResponse } from '.';

const COLLECTION = 'adminUsers';

export const getAdminUsers = async (
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<AdminUserModel>> => {
  const sort = getSort(dtSort) || { name: 1 };

  const skip = page * pageSize;
  const filter = getOrgFilter();

  if (dtFilter) {
    const { values, keyword } = dtFilter;

    if (keyword) {
      filter.$or = [
        { name: { $regex: `.*${keyword}.*`, $options: 'i' } },
        { email: { $regex: `.*${keyword}.*`, $options: 'i' } },
      ];
    }

    values.forEach(({ columnName, value }) => {
      switch (columnName) {
        case 'role':
          filter.role = value;
          break;
      }
    });
  }

  const { documents, total } = await findAllAndCount(COLLECTION, filter, pageSize, skip, sort);

  const rows: AdminUserModel[] = documents.map((data) => new AdminUserModel(data));

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getAdminUserById = async (userId: string): Promise<AdminUserModel | null> => {
  const params = {
    filter: {
      _id: { $oid: userId },
      ...getOrgFilter(),
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new AdminUserModel(response) : null;
};

export const patchAdminUser = async (adminUser: AdminUserModel): Promise<UpdateResponse> => {
  // cannot change last owner role to non-owner
  if (adminUser.role !== 'org:owner') {
    const existUser = await getAdminUserById(adminUser.id);
    if (existUser?.role === 'org:owner') {
      const ownerCount = await getOwnerCount();
      if (ownerCount < 2) {
        return { updated: false, error: 'You cannot change the role of the last owner' };
      }
    }
  }

  const filter = {
    _id: { $oid: adminUser.id },
    ...getOrgFilter(),
  };

  const createUser = new CreateAdminUserModel(adminUser);

  const response = await patchOne(COLLECTION, filter, createUser._props);

  return { updated: response === null };
};

export const createAdminUser = async (user: AdminUserModel): Promise<string | null> => {
  const { orgId } = getOrgFilter();

  const createUser = new CreateAdminUserModel(user);

  const document = {
    ...createUser._props,
    orgId,
  };

  return insertOne(COLLECTION, document);
};

export const deleteAdminUser = async (userId: string): Promise<DeleteResponse> => {
  // cannot delete last owner
  const ownerCount = await getOwnerCount();
  if (ownerCount < 2) {
    return { deleted: false, error: 'You cannot delete the last owner' };
  }

  const filter = {
    _id: { $oid: userId },
    ...getOrgFilter(),
  };

  const deleted = await deleteOne(COLLECTION, filter);

  return { deleted };
};

// @todo perf just get a count, don't return documents
export const getOwnerCount = async (): Promise<number> => {
  const response = await getAdminUsers(0, DEFAULT_PAGE_SIZE, undefined, {
    keyword: '',
    fields: [],
    values: [{ columnName: 'role', value: 'org:owner' }],
  });

  return response?.rows?.length || 0;
};
