import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { getFirstLastName } from '../../lib/helpers';
import { createUseStyles } from 'react-jss';
import Menu from '../menu';
import UserBadge from './user-badge';
import Text from '../text';
import Button from '../button';
import useApp from '@/hooks/use-app.hook';
import useAppData from '@/hooks/use-app-data.hook';
import { OrgBasicInfo } from '@/lib/models/org.model';
import { AppDataQueryKey } from '@/lib/query-client';
import { AuthRole, AuthRoleLabels } from '@/lib/services/auth.service';
import useUserControl from '@/hooks/use-user-control.hook';

const useStyles = createUseStyles((theme: Theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 16,
    paddingTop: 16,
  },
}));

const UserMenu: FC = () => {
  const styles = useStyles();
  const { user, signOut } = useApp();
  const { checkLockedAndEmit } = useUserControl();

  const { displayName, email, assumed } = user!;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasName = Boolean(first) || Boolean(last);
  const assumeActive = !!assumed;

  let NameEl = <Text size="large">{email}</Text>;

  if (hasName) {
    NameEl = (
      <Stack gap={0.5}>
        <Text size="large">{[first, last].join(' ')}</Text>
        <Text color="grey">{email}</Text>
      </Stack>
    );
  }

  const { data } = useAppData<OrgBasicInfo>(AppDataQueryKey.OrgBasicInfo);
  const { name } = data || {};
  const roleLabel = user?.role ? AuthRoleLabels[user.role as AuthRole] : '';

  const handleSignOut = () => {
    if (checkLockedAndEmit()) {
      return false;
    }
    signOut();
  };

  return (
    <Box>
      <Menu trigger={<UserBadge user={user!} />}>
        {NameEl}

        <Stack gap={0.4} mt={2}>
          <Text>{name}</Text>
          <Text color="grey">{roleLabel}</Text>
        </Stack>

        {!assumeActive && (
          <Box className={styles.footer} display="flex" justifyContent="flex-end">
            <Button label="Sign Out" onClick={handleSignOut} size="small" />
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default UserMenu;
