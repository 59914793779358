import AccordionButton from '@/components/accordion-button';
import InputControl from '@/components/form/input-control';
import TextInput from '@/components/form/text-input';
import Icon from '@/components/icon';
import Text from '@/components/text';
import { FormHandle } from '@/hooks/use-form-handle.hook';
import { DateFormat, getNiceDate } from '@/lib/helpers';
import { getTypedIdentProvider } from '@/lib/models/ident-provider';
import { IdentProviderModel } from '@/lib/models/ident-provider/ident-provider.model';
import { SamlIdentProviderModel } from '@/lib/models/ident-provider/saml-ident-provider.model';
import { getAuthCallbackUrl } from '@/lib/services/auth.service';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  formHandle: FormHandle<IdentProviderModel>;
  provider: SamlIdentProviderModel;
}

const useStyles = createUseStyles({
  samlForm: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
});

const SamlIdentProviderForm: FC<Props> = ({ formHandle, provider }) => {
  const styles = useStyles();
  const { values, setFieldValue } = formHandle;

  const typedProvider = getTypedIdentProvider<SamlIdentProviderModel>(values);
  const spEntityId = typedProvider.identityProviderConfig.spEntityId;

  const hasCallbackURI = !!typedProvider.identityProviderConfig.callbackUrl;
  const callbackURI = hasCallbackURI
    ? typedProvider.identityProviderConfig.callbackUrl
    : getAuthCallbackUrl();

  // the ident service PATCH call expects cert updates to be a string[], but the rawIdpCertificate field
  // below is defined as just a string, so ensure that value is provided in the expected format
  const handleChangeCert = (value: string) => {
    setFieldValue('identityProviderConfig.rawIdpCertificates', [value]);
  };

  const { parsedCertificate, certDates, type } = provider;
  const hasCert = !!parsedCertificate;
  const disabledByType = ['SAML-URL', 'SAML-XML'].includes(type);

  const CertTitleEl = (
    <Stack gap={0.5} direction="row" alignItems="center">
      <Icon name="certificate" size="small" />
      <Text size="small" bold>
        Installed Certificate Details
      </Text>
    </Stack>
  );

  return (
    <Stack gap={2} className={styles.samlForm}>
      <Box>
        <Box mb={1}>
          <Text bold>Service Provider Entity ID</Text>
        </Box>

        <TextInput name="spEntityId" value={spEntityId} copyClip size="small" readonly />
      </Box>

      <Box>
        <Box mb={1}>
          <Text bold>Authorization Callback URL</Text>
        </Box>
        <TextInput name="authCallbackURI" value={callbackURI} copyClip size="small" readonly />
      </Box>

      <Box>
        <InputControl
          name="identityProviderConfig.idpEntityId"
          label="Entity Id"
          formHandle={formHandle}
          readonly={disabledByType}
          copyClip={disabledByType}
        />
      </Box>

      <Box>
        <InputControl
          name="identityProviderConfig.idpSSOUrl"
          label="SSO URL"
          formHandle={formHandle}
          readonly={disabledByType}
          copyClip={disabledByType}
        />
      </Box>

      <Box>
        {hasCert && (
          <Box mt={1}>
            <AccordionButton title={CertTitleEl}>
              <Box mb={2}>
                <Box mb={1}>
                  <Text color="grey" size="small" bold>
                    Certificate Fingerprint:
                  </Text>
                </Box>
                <Text>{parsedCertificate.x509Fingerprint || 'n/a'}</Text>
              </Box>

              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Text color="grey" size="small">
                    Valid From: {getNiceDate(certDates[0], DateFormat.FRIEND).formatted || 'n/a'}
                  </Text>
                </Box>
                <Text color="grey" size="small">
                  Valid To: {getNiceDate(certDates[1], DateFormat.FRIEND).formatted || 'n/a'}
                </Text>
              </Stack>
            </AccordionButton>
          </Box>
        )}

        {!disabledByType && (
          <InputControl
            type="textarea"
            name="identityProviderConfig.rawIdpCertificate"
            formHandle={formHandle}
            rows={3}
            label="New Provider Certificate"
            onChange={handleChangeCert}
          />
        )}
      </Box>
    </Stack>
  );
};

export default SamlIdentProviderForm;
