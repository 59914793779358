import { BaseModel } from './base.model';
import { isValidEmail, JsonObject } from '../helpers';
import { object, string } from 'yup';

type UserExtRef = {
  workos: {
    workosUserId: string;
    workosOrganizationId: string | null;
    workosDirectoryId: string;
    workosCreatedAt: string;
    workosUpdatedAt: string;
  };
};

export class UserModel extends BaseModel {
  orgId: string;
  name: string;
  email: string;
  groups: string[];
  externalReferences: UserExtRef;
  lastSigninTimestamp?: number;

  schema = object({
    name: string(), // unfortunately we cannot require this field because some users come in from scim sync and this field is not brought over for some reason
    email: string()
      .test('email', 'Please enter a valid email address', isValidEmail)
      .required('Please enter an email address'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof UserModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.email = (data.email as string) || '';
    this.groups = (data.groups as string[]) || [];

    this.externalReferences = (data.externalReferences as UserExtRef) || {
      workos: {
        workosUserId: '',
        workosOrganizationId: null,
        workosDirectoryId: '',
        workosCreatedAt: '',
        workosUpdatedAt: '',
      },
    };

    if (data.lastSigninTimestamp) {
      this.lastSigninTimestamp = data.lastSigninTimestamp as number;
    }
  }

  get source(): string {
    if (this.externalReferences?.workos?.workosDirectoryId) {
      return 'sync';
    }

    if (this.hasLogin) {
      return 'login';
    }

    return 'surepath';
  }

  get sourceLabel(): string {
    switch (this.source) {
      case 'sync':
        return 'Sync';
      case 'login':
        return 'Login';
      default:
        return 'SurePath AI';
    }
  }

  get isExternal(): boolean {
    return !!this.externalReferences?.workos?.workosDirectoryId;
  }

  get hasLogin(): boolean {
    return !!this.lastSigninTimestamp;
  }

  get canDelete(): boolean {
    return !this.isExternal && !this.hasLogin;
  }
}
