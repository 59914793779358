import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { NetskopeConnectorModel } from '@/lib/models/connector/netskope-connector.model';

interface Props {
  connector: NetskopeConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const NetskopeConnector: FC<Props> = ({ formHandle, mode }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);

  if (!inWriteMode) {
    return null;
  }

  return (
    <Box>
      <InputControl name="config.tenantInfo" label="Tenant Info" formHandle={formHandle} />

      <InputControl
        name="config.xAuthenticatedUserEnabled"
        label="Enable X-Authenticated-User Header"
        formHandle={formHandle}
        type="switch"
      />
    </Box>
  );
};

export default NetskopeConnector;
