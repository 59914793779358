import { ForwardedRef, forwardRef, MouseEvent, useState } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Avatar, Box, SvgIconTypeMap, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import PeopleIcon from '@mui/icons-material/People';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import PublicIcon from '@mui/icons-material/Public';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PolicyIcon from '@mui/icons-material/Policy';
import TuneIcon from '@mui/icons-material/Tune';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import DataObjectIcon from '@mui/icons-material/DataObject';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PasswordIcon from '@mui/icons-material/Password';
import GoogleIcon from '@mui/icons-material/Google';
import PersonIcon from '@mui/icons-material/Person';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BusinessIcon from '@mui/icons-material/Business';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DownloadIcon from '@mui/icons-material/Download';
import SelectAll from '@mui/icons-material/SelectAll';
import LinkIcon from '@mui/icons-material/Link';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import LockClockIcon from '@mui/icons-material/LockClock';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ImageIcon from '@mui/icons-material/Image';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import MailLockIcon from '@mui/icons-material/MailLock';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SortIcon from '@mui/icons-material/Sort';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AssistantIcon from '@mui/icons-material/Assistant';
import BadgeIcon from '@mui/icons-material/Badge';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RuleIcon from '@mui/icons-material/Rule';
import DownloadingIcon from '@mui/icons-material/Downloading';
import SummarizeIcon from '@mui/icons-material/Summarize';

const useStyles = createUseStyles<string, { block: boolean }>((theme: Theme) => ({
  icon: {
    '&.button': {
      cursor: 'pointer',
    },
    '&.white .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
    '&.black .MuiSvgIcon-root': {
      color: theme.palette.common.black,
    },
    '&.grey .MuiSvgIcon-root': {
      color: theme.palette.grey[400],
    },
    '&.active .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    '&.success .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    },
    '&.error .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
    '&.link .MuiSvgIcon-root': {
      color: theme.palette.link.main,
    },
    '&.disabled': {
      cursor: 'default',
      opacity: 0.2,
    },
  },
  iconBackground: {
    backgroundColor: theme.palette.secondary.light,
    '&.second': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  iconWrapper: {
    display: ({ block }) => (block ? 'block' : 'inline-block'),
    lineHeight: '70%',
    '&.x-small': {
      lineHeight: '24px',
    },
  },
  spin: {
    animation: '$spin 400ms linear',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export type IconType =
  | 'people'
  | 'supervisor'
  | 'person'
  | 'bar-chart'
  | 'layers'
  | 'chevron-left'
  | 'chevron-right'
  | 'expand'
  | 'collapse'
  | 'menu'
  | 'notifs'
  | 'lock'
  | 'log-out'
  | 'chat'
  | 'globe'
  | 'enterprise'
  | 'policy'
  | 'tune'
  | 'article'
  | 'close'
  | 'search'
  | 'menu-arrow'
  | 'refresh'
  | 'plus-circle'
  | 'plus'
  | 'json'
  | 'info-circle'
  | 'arrow-right'
  | 'arrow-left'
  | 'text-file'
  | 'edit'
  | 'google'
  | 'password'
  | 'delete'
  | 'copy'
  | 'save'
  | 'cancel'
  | 'group'
  | 'groups'
  | 'lock-person'
  | 'model-training'
  | 'check-circle'
  | 'check'
  | 'more-vert'
  | 'organization'
  | 'network-error'
  | 'deny'
  | 'download'
  | 'link'
  | 'assignment'
  | 'time-lapse'
  | 'lock-clock'
  | 'doc-search'
  | 'select-all'
  | 'question-mark'
  | 'arrow-double-up'
  | 'arrow-double-down'
  | 'upload'
  | 'image'
  | 'vector'
  | 'cloudsync'
  | 'settings'
  | 'assume-role'
  | 'plug'
  | 'assessment'
  | 'magic-link'
  | 'alpha-sort'
  | 'sort'
  | 'unlock'
  | 'assistant'
  | 'certificate'
  | 'telemetry'
  | 'test'
  | 'export'
  | 'report';

type IconElementType = OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
  muiName: string;
};

const IconMap: Map<IconType, IconElementType> = new Map();
IconMap.set('people', PeopleIcon);
IconMap.set('supervisor', SupervisedUserCircleIcon);
IconMap.set('person', PersonIcon);
IconMap.set('bar-chart', BarChartIcon);
IconMap.set('layers', LayersIcon);
IconMap.set('chevron-left', ChevronLeftIcon);
IconMap.set('chevron-right', ChevronRightIcon);
IconMap.set('menu', MenuIcon);
IconMap.set('notifs', NotificationsIcon);
IconMap.set('lock', LockOutlinedIcon);
IconMap.set('log-out', LogoutOutlined);
IconMap.set('chat', ForumIcon);
IconMap.set('globe', PublicIcon);
IconMap.set('enterprise', CorporateFareIcon);
IconMap.set('policy', PolicyIcon);
IconMap.set('tune', TuneIcon);
IconMap.set('article', ArticleIcon);
IconMap.set('close', CloseIcon);
IconMap.set('search', SearchIcon);
IconMap.set('menu-arrow', ArrowDropDownIcon);
IconMap.set('refresh', RefreshIcon);
IconMap.set('plus-circle', AddCircleIcon);
IconMap.set('plus', AddIcon);
IconMap.set('json', DataObjectIcon);
IconMap.set('info-circle', InfoIcon);
IconMap.set('arrow-right', ArrowForwardIcon);
IconMap.set('arrow-left', ArrowBackIcon);
IconMap.set('text-file', TextSnippetIcon);
IconMap.set('edit', EditNoteIcon);
IconMap.set('google', GoogleIcon);
IconMap.set('password', PasswordIcon);
IconMap.set('delete', RemoveCircleIcon);
IconMap.set('copy', ContentCopyIcon);
IconMap.set('save', SaveIcon);
IconMap.set('cancel', CancelIcon);
IconMap.set('group', GroupIcon);
IconMap.set('groups', GroupsIcon);
IconMap.set('lock-person', LockPersonIcon);
IconMap.set('model-training', ModelTrainingIcon);
IconMap.set('check-circle', CheckCircleIcon);
IconMap.set('check', CheckIcon);
IconMap.set('more-vert', MoreVertIcon);
IconMap.set('organization', BusinessIcon);
IconMap.set('network-error', WarningAmberIcon);
IconMap.set('deny', DoNotDisturbIcon);
IconMap.set('download', DownloadIcon);
IconMap.set('link', LinkIcon);
IconMap.set('assignment', AssignmentIcon);
IconMap.set('time-lapse', TimelapseIcon);
IconMap.set('lock-clock', LockClockIcon);
IconMap.set('doc-search', DocumentScannerIcon);
IconMap.set('select-all', SelectAll);
IconMap.set('question-mark', QuestionMarkIcon);
IconMap.set('arrow-double-up', KeyboardDoubleArrowUpIcon);
IconMap.set('arrow-double-down', KeyboardDoubleArrowDownIcon);
IconMap.set('expand', ExpandMoreIcon);
IconMap.set('collapse', ExpandLessIcon);
IconMap.set('upload', FileUploadIcon);
IconMap.set('image', ImageIcon);
IconMap.set('vector', CallMadeIcon);
IconMap.set('cloudsync', CloudSyncIcon);
IconMap.set('settings', SettingsIcon);
IconMap.set('assume-role', AdminPanelSettingsIcon);
IconMap.set('plug', SettingsInputCompositeIcon);
IconMap.set('assessment', AssessmentIcon);
IconMap.set('magic-link', MailLockIcon);
IconMap.set('alpha-sort', SortByAlphaIcon);
IconMap.set('sort', SortIcon);
IconMap.set('unlock', LockOpenIcon);
IconMap.set('assistant', AssistantIcon);
IconMap.set('certificate', BadgeIcon);
IconMap.set('telemetry', CopyAllIcon);
IconMap.set('test', RuleIcon);
IconMap.set('export', DownloadingIcon);
IconMap.set('report', SummarizeIcon);

interface Props {
  name: IconType;
  onClick?: (event: MouseEvent<SVGSVGElement>) => void;
  spinOnClick?: boolean;
  background?: 'first' | 'second';
  className?: string;
  color?: 'white' | 'black' | 'grey' | 'active' | 'success' | 'error' | 'link';
  size?: 'medium' | 'x-small' | 'small' | 'large' | 'x-large';
  disabled?: boolean;
  ref?: ForwardedRef<SVGSVGElement>;
  block?: boolean;
}

type IconFontSizeLabel = 'small' | 'medium' | 'large';

const getSizeProp = (
  size: string
): { fontSize: IconFontSizeLabel } | { sx: { fontSize: number } } => {
  if (['small', 'medium', 'large'].includes(size)) {
    return { fontSize: size as IconFontSizeLabel };
  }

  switch (size) {
    case 'x-small':
      return { sx: { fontSize: 14 } };
    case 'x-large':
      return { sx: { fontSize: 50 } };
  }

  return { fontSize: 'medium' };
};

const Icon = forwardRef<SVGSVGElement, Props>(
  (
    {
      color = '',
      name,
      onClick,
      spinOnClick = false,
      background,
      className = '',
      size = 'medium',
      disabled = false,
      block = false,
    },
    ref
  ) => {
    const styles = useStyles({ block });
    const Icon = IconMap.get(name);
    const [spin, setSpin] = useState(false);

    if (!Icon) {
      console.warn(`unknown icon type ${name}`);
      return null;
    }

    const handleClick = (event: MouseEvent<SVGSVGElement>) => {
      if (disabled) {
        return;
      }
      onClick && onClick(event);
      spinOnClick && setSpin(true);
    };

    const handleStopSpin = () => {
      setSpin(false);
    };

    const canClick = Boolean(onClick);
    const role = canClick ? 'button' : 'presentation';

    const IconEl = <Icon role={role} onClick={handleClick} {...getSizeProp(size)} ref={ref} />;

    if (background) {
      const avatarClass = classNames(
        styles.icon,
        styles.iconBackground,
        className,
        background,
        color,
        canClick && 'button'
      );
      return <Avatar className={avatarClass}>{IconEl}</Avatar>;
    }

    const boxClass = classNames(
      styles.icon,
      styles.iconWrapper,
      spin && styles.spin,
      className,
      color,
      { disabled, button: canClick },
      size
    );
    return (
      <Box onAnimationEnd={handleStopSpin} className={boxClass}>
        {IconEl}
      </Box>
    );
  }
);

export default Icon;
