import { AxiosError, AxiosResponse, CanceledError } from 'axios';
import { JsonObject } from '../helpers';
import { BaseModel } from '../models/base.model';

export const DEFAULT_SERVICE_ERROR = 'There was a service error';

export const SAFE_LIMIT = 1000;

export type FieldError = {
  type?: string;
  value: unknown;
  msg: string;
  path: string;
  location?: string;
};

export type CreateResponse<T = BaseModel> = {
  created: boolean;
  error?: string;
  fieldErrors?: FieldError[];
  data?: JsonObject;
  model?: T;
};

export type PatchResponse = {
  patched: boolean;
  error?: string;
  fieldErrors?: FieldError[];
  data?: JsonObject;
};

export type PatchErrorHandler = (error: AxiosError) => PatchResponse;

export type InsertResponse = { inserted: boolean; error?: string; id?: string };

export type UpdateResponse = { updated: boolean; error?: string; fieldErrors?: FieldError[] };

export type UpdateErrorHandler = (error: AxiosError) => UpdateResponse;

export type CreateErrorHandler = (error: AxiosError) => CreateResponse;

export type PostErrorHandler = <T>(error: AxiosError) => T | null;

export type DeleteResponse = { deleted: boolean; error?: string };

export type ApiResponse = JsonObject | JsonObject[] | null;

export const handlePostResponse = <T>(response: AxiosResponse | null): T | null => {
  return (response?.data as T) || null;
};

export const handlePostErrorResponse = <T>(error: AxiosError): T | null => {
  return (error?.response?.data as T) || null;
};

export const passErrorResponse = (err: unknown) => {
  throw err;
};

export const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};

export const handleCreateResponse = (response: AxiosResponse | null): CreateResponse => {
  if (!response?.data) {
    return { created: false, error: DEFAULT_SERVICE_ERROR };
  }

  return { created: true, data: response.data as JsonObject };
};

export const handleCreateErrorResponse = (error: AxiosError): CreateResponse => {
  if (!error.response) {
    return { created: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response;

  if (status === 400 && Array.isArray(data)) {
    return { created: false, fieldErrors: data as FieldError[] };
  }

  return { created: false, error: DEFAULT_SERVICE_ERROR };
};

export const handleUpdateResponse = (response: AxiosResponse | null): UpdateResponse => {
  if (!response?.data) {
    return { updated: false, error: DEFAULT_SERVICE_ERROR };
  }

  return { updated: true };
};

export const handleUpdateErrorResponse = (): UpdateResponse => {
  return { updated: false, error: DEFAULT_SERVICE_ERROR };
};

export const handlePatchResponse = (response: AxiosResponse | null): PatchResponse => {
  if (!response?.data) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  return { patched: true, data: response.data as JsonObject };
};

export const handlePatchErrorResponse = (error: AxiosError): PatchResponse => {
  if (!error.response) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response;

  if (status === 400 && Array.isArray(data)) {
    return { patched: false, fieldErrors: data as FieldError[] };
  }

  return { patched: false, error: DEFAULT_SERVICE_ERROR };
};

export const handleDeleteResponse = (): DeleteResponse => {
  return { deleted: true };
};

export const handleDeleteErrorResponse = (): DeleteResponse => {
  return { deleted: false, error: DEFAULT_SERVICE_ERROR };
};

// https://github.com/axios/axios?tab=readme-ov-file#error-types
export const getAxiosErrorMessage = (
  err: unknown,
  errorCodes?: Record<number, string>
): { message: string; clientside: boolean } => {
  const { code = '', status = 0 } = (err || {}) as AxiosError;

  if (err instanceof CanceledError || code === 'ERR_CANCELED') {
    return { message: 'You cancelled the request', clientside: true };
  }

  let message = 'There was a service error';

  if (['ECONNABORTED', 'ETIMEDOUT', 'ERR_NETWORK', 'ERR_FR_TOO_MANY_REDIRECTS'].includes(code)) {
    message = 'There was a network error';
  }

  if (
    [
      'ERR_BAD_OPTION_VALUE',
      'ERR_BAD_OPTION',
      'ERR_DEPRECATED',
      'ERR_NOT_SUPPORT',
      'ERR_INVALID_URL',
    ].includes(code)
  ) {
    message = 'There was an application error';
  }

  // user-defined response code
  if (errorCodes?.[status]) {
    message = errorCodes[status];
  }

  return { message, clientside: false };
};
