import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createProvider,
  deleteProvider,
  getProviderById,
  getProviderUsage,
  patchProvider,
} from '@/lib/services/context-provider.service';
import Drawer, { DrawerProps } from '../';
import DrawerContent from '../content';
import DrawerHeader from '../header';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import { QueryKey } from '@/lib/query-client';
import { ContextProviderReadonlyView } from './readonly-view';
import useModal from '@/hooks/use-modal.hook';
import useToast from '@/hooks/use-toast.hook';
import {
  ContextProviderTypeOptions,
  ContextProviderScanScheduleOptions,
  ContextProviderType,
  getTypedContextProvider,
} from '@/lib/models/context-provider';
import DrawerToolbar from '../toolbar';
import { UiOption, ViewState } from '@/lib/helpers';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import DrawerButtons from '@/components/form/drawer-buttons';
import InputControl from '@/components/form/input-control';
import Select from '@/components/form/select';
import WebcrawlerContextProvider from './webcrawler-context-provider';
import { FieldError } from '@/lib/services';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { getContextProviderConnectors } from '@/lib/services/connector.service';
import SharepointContextProvider from './sharepoint-context-provider';
import useFeature from '@/hooks/use-feature';
import AwsKbContextProvider from './aws-kb-context-provider';

interface Props extends DrawerProps {
  id: string;
}

const formModel = new ContextProviderModel();

// @todo remove once datasource controller supports PATCH or PUT
const DISABLE_EDIT_MODE = true;

const ContextProviderDrawer: FC<Props> = ({ id, open, onClose, className, onChange }) => {
  const [providerId, setProviderId] = useState(id || '');
  const [providerType, setProviderType] = useState<ContextProviderType | ''>('');
  const [connectorId, setConnectorId] = useState('');

  const { canChangeContextProviders } = useFeature();

  const isNew = !providerId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'add' : 'view');

  const query = useQuery(
    [QueryKey.ContextProviderView, providerId],
    async () => getProviderById(providerId),
    {
      enabled: !!providerId,
    }
  );
  const { showLoader: providerLoading } = useQueryHelper(query);
  const { data = new ContextProviderModel(), refetch } = query;
  const provider = data!;

  const connectorQuery = useQuery<ConnectorModel[]>(QueryKey.ConnectorProvidersList, async () =>
    getContextProviderConnectors()
  );
  const { data: availableConnectors = [] } = connectorQuery;
  const { showLoader: connectorsLoading } = useQueryHelper(connectorQuery);

  const connectorOpts: UiOption[] = availableConnectors.map(
    ({ id, name, status, comingSoon, contextProviderSupportComingSoon }) => ({
      label: comingSoon || contextProviderSupportComingSoon ? `${name} (Coming Soon)` : name,
      value: id,
      disabled: status !== 'active' || comingSoon || contextProviderSupportComingSoon,
    })
  );

  const usageQuery = useQuery(
    [QueryKey.ContextProviderUsage, providerId],
    async () => getProviderUsage([providerId]),
    { enabled: !!providerId }
  );
  const { data: providerUsageMap } = usageQuery;
  const { showLoader: usageLoading } = useQueryHelper(usageQuery);

  const [provideTypeDisabled, providerTypeOptions, connector]: [
    boolean,
    UiOption[],
    ConnectorModel | null,
  ] = useMemo(() => {
    const connector = availableConnectors.find(({ id }) => id === connectorId);

    if (!connector) {
      return [true, [], null];
    }

    const availableTypeOptions = ContextProviderTypeOptions.filter(({ value }) =>
      connector.contextProviderTypes.includes(value as ContextProviderType)
    );

    return [false, availableTypeOptions, connector];
  }, [connectorId, availableConnectors]);

  const { openModal } = useModal();
  const { errorToast, toast } = useToast();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (provider, { setFieldError, resetForm }) => {
      if (!providerType) {
        return;
      }

      const setFieldErrors = (fieldErrors: FieldError[]) => {
        fieldErrors.forEach(({ msg, path }) => setFieldError(path, msg));
      };

      provider.type = providerType;

      if (isNew) {
        const { fieldErrors, error, created, model } = await createProvider(provider);

        if (fieldErrors) {
          setFieldErrors(fieldErrors);
          return;
        }

        if (error || !created) {
          errorToast(error || 'The data source was not created');
          return;
        }

        resetForm({ values });
        toast('The data source was created');
        setProviderId(model!.id);
        onChange?.();
        setMode('view');

        return;
      }

      const { fieldErrors, patched, error } = await patchProvider(provider);

      if (fieldErrors) {
        setFieldErrors(fieldErrors);
        return;
      }

      if (error || !patched) {
        errorToast(error || 'The data source was not updated');
        return;
      }

      resetForm({ values });
      toast('The data source was updated');
      refetch();
      onChange && onChange();
      setMode('view');
    },
  });

  const { canSubmit, isSubmitting, handleSubmit, loadData, values, setValues, handleBlur } =
    formHandle;

  const handleDelete = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: <>Are you sure you want to delete {provider.name}?</>,
      onClose: (confirm: boolean) => {
        if (confirm) {
          deleteProvider(provider.id).then(({ deleted, error }) => {
            if (!deleted) {
              errorToast(error || 'The data source was not deleted');
              return;
            }

            setProviderId('');
            setProviderType('surepath-kendra-webcrawler');
            onClose();
            toast('The data source was deleted');
            onChange && onChange();
          });
        }
      },
    });
  };

  const handleCancel = () => {
    if (isNew) {
      onClose(true);
      return;
    }
    setMode('view');
    loadData(provider);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleChangeProviderType = useCallback(
    (value: string) => {
      if (!isNew) {
        return;
      }

      const providerType = value as ContextProviderType;
      setProviderType(providerType);
      values.type = providerType;
      setValues(getTypedContextProvider(values));
    },
    [isNew, setValues, values]
  );

  const handleChangeConnectorId = (value: string) => {
    if (!isNew) {
      return;
    }

    setConnectorId(value);
    values.connectorId = value;
    setValues(getTypedContextProvider(values));
  };

  useEffect(() => {
    if (provider?.id) {
      loadData(getTypedContextProvider(provider));
      setProviderId(provider.id);
      setProviderType(provider.type);
      setConnectorId(provider.connectorId || '');
    }
  }, [provider, loadData]);

  useEffect(() => {
    if (providerTypeOptions.length !== 1) {
      return;
    }

    const { value: singleType } = providerTypeOptions[0];

    if (providerType !== singleType) {
      handleChangeProviderType(singleType);
    }
  }, [providerTypeOptions, handleChangeProviderType, providerType]);

  const inWriteMode = ['edit', 'add'].includes(mode);
  const showChildren = !providerLoading && !connectorsLoading && !usageLoading;
  const hasProviderType = !!providerType;
  const { scanable } = values;
  const comingSoon = !!values?.comingSoon;
  const providerUsage = providerUsageMap?.get(providerId) || {
    inUse: false,
    policies: [],
    agents: [],
  };
  const hasUsage = !!providerUsage.inUse;
  const canDelete = !hasUsage;

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>{provider.name}</DrawerHeader>
      <DrawerContent>
        {!isNew && canChangeContextProviders && !comingSoon && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={!inWriteMode && !DISABLE_EDIT_MODE}
            canDelete={canDelete}
            deleteTooltip={!canDelete ? 'You cannot delete a data source that is in use' : ''}
          />
        )}

        {inWriteMode && (
          <>
            <InputControl name="name" label="Name" formHandle={formHandle} autoFocus />

            <InputControl
              name="description"
              label="Description"
              type="textarea"
              rows={2}
              formHandle={formHandle}
            />

            <InputControl
              name="connectorId"
              label="Connector"
              formHandle={formHandle}
              type="select"
              options={connectorOpts}
              readonly={!isNew}
              onBlur={handleBlur}
              onChange={handleChangeConnectorId}
            />
            <Select
              name="type"
              value={providerType}
              label="Type"
              options={providerTypeOptions}
              readonly={!isNew || provideTypeDisabled}
              onBlur={handleBlur}
              onChange={handleChangeProviderType}
            />
          </>
        )}

        {providerType === 'surepath-kendra-webcrawler' && (
          <WebcrawlerContextProvider
            formHandle={formHandle}
            provider={provider}
            mode={mode}
            connector={connector}
          />
        )}

        {providerType === 'surepath-kendra-sharepoint' && (
          <SharepointContextProvider
            formHandle={formHandle}
            provider={provider}
            mode={mode}
            connector={connector}
          />
        )}

        {providerType === 'customer-bedrock-knowledge-base' && (
          <AwsKbContextProvider
            formHandle={formHandle}
            mode={mode}
            provider={provider}
            connector={connector}
          />
        )}

        {inWriteMode && hasProviderType && scanable && (
          <InputControl
            name="scanSchedule"
            label="Scan Schedule"
            formHandle={formHandle}
            type="select"
            options={ContextProviderScanScheduleOptions}
          />
        )}

        {!inWriteMode && showChildren && (
          <ContextProviderReadonlyView
            provider={provider}
            connector={connector}
            usage={providerUsage}
          />
        )}

        {inWriteMode && (
          <DrawerButtons
            submit
            cancel
            isNew={isNew}
            canSubmit={canSubmit}
            canCancel={!isSubmitting}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            formHandle={formHandle}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ContextProviderDrawer;
