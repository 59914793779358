import { ReactNode } from 'react';
import { GridSortModel, GridColDef } from '@mui/x-data-grid';
import has from 'lodash/has';
import { InputControlType } from '../form/input-control';
import { uniqueId } from 'lodash';
import { UiOption } from '@/lib/helpers';

export const DEFAULT_PAGE_SIZE = 10;

export type DtSort = {
  columnName: string;
  direction: 'asc' | 'desc';
};

export type DtFilterField = {
  columnName: string;
  label?: string;
  control?: InputControlType;
  input?: ReactNode;
  options?: UiOption[];
  highlander?: boolean;
  onSearch?: (value: string) => Promise<UiOption[]>;
  searchLabel?: string;
  maxDate?: string | Date;
  minDate?: string | Date;
};

export type DtFilterValue = {
  columnName: string;
  value: unknown;
};

export type DtFilter = {
  keyword: string;
  fields: DtFilterField[];
  values: DtFilterValue[];
};

export type DtParams = { page: number; pageSize: number; sort?: string; sortDirection?: string };

export type DtPage = {
  page: number;
  pageSize: number;
  sort?: DtSort;
  filter?: DtFilter;
};

export type DtColumn = {
  name: string;
  label: string;
  format?: string;
  decimals?: number;
  flex?: number;
  sortable?: boolean;
};

export type Dataset<T> = DtPage & {
  rows: T[];
  total: number;
};

export const getDatasetParams = (page: number, pageSize: number, sort?: DtSort): DtParams => {
  const { columnName, direction } = sort || {};
  const params: DtParams = {
    page,
    pageSize,
  };

  if (columnName) {
    params.sort = columnName;
    params.sortDirection = direction || 'asc';
  }

  return params;
};

export const makeMuiDataGridColumns = (columns: DtColumn[]): GridColDef[] => {
  // https://mui.com/x/react-data-grid/column-dimensions/
  return columns.map(({ name, label, flex = 1, sortable = true }) => {
    return {
      field: name,
      headerName: label,
      flex,
      editable: false,
      sortable,
      // @todo pull from api metadata
      // https://mui.com/x/react-data-grid/column-definition/#column-types
      type: 'string',
      renderCell: (params: { value?: ReactNode }) => {
        let { value } = params;

        if (value === null) {
          value = <span className="null-value">null</span>;
        }

        return value;
      },
    };
  });
};

export const makeMuiSort = ({ columnName, direction }: DtSort) => ({
  field: columnName,
  sort: direction,
});

export const makeDtSort = (muiSort: GridSortModel): DtSort => {
  if (!muiSort.length) {
    return { columnName: '', direction: 'asc' };
  }

  // not that multi-column sorting is not supported
  const { field: columnName, sort: direction } = muiSort[0];

  return { columnName, direction: direction || 'asc' };
};

export const makeMuiRows = (
  rows: Record<string, string | number | ReactNode>[],
  columns: DtColumn[]
): Record<string, ReactNode>[] => {
  const columnMap: Map<string, DtColumn> = new Map();
  columns.forEach((column) => {
    columnMap.set(column.name, column);
  });

  return rows.map((row) => {
    Array.from(columnMap.keys()).forEach((columnName) => {
      if (!has(row, columnName)) {
        return;
      }
      let val: ReactNode = row[columnName];

      if (val === null) {
        return;
      }

      const { format, decimals = -1 } = columnMap.get(columnName) || {};

      if (decimals > -1) {
        val = Number(val).toFixed(decimals);
      }

      switch (format) {
        case 'percent':
          val = `${val as string}%`;
          break;
      }

      row[columnName] = val;
    });

    // if this is not set, MUI Data Grid will not render
    if (!row.id) {
      console.warn(`datatable row missing id, making something up`);
      row.id = uniqueId('datatable-');
    }

    return row;
  });
};

const getNicePageSize = (rowHeight: number, availableHeight?: number): number => {
  // get the height of the space available for the table, with a reasonable fallback
  const safeHeight = availableHeight || window.innerHeight - 430;

  // subtract the height of non-row elements of the table
  const dtHeights = [
    55, // pagination
    72, // toolbar
    56, // column header row
  ];

  const compHeight = safeHeight - dtHeights.reduce((netHeight, height) => netHeight + height, 0);

  const niceSize = Math.floor(compHeight / rowHeight);

  if (niceSize > 10) {
    return 10;
  }

  if (niceSize < 2) {
    return 2;
  }

  return niceSize;
};

export const getNiceRowsPerPage = (
  rowHeight: number,
  rowsPerPage: number[],
  availableHeight?: number
): number[] => {
  const updatedRowsPerPage = [...rowsPerPage];
  const nicePageSize = getNicePageSize(rowHeight, availableHeight);

  if (!updatedRowsPerPage.includes(nicePageSize)) {
    if (updatedRowsPerPage.length === 1) {
      updatedRowsPerPage[0] = nicePageSize;
    } else {
      updatedRowsPerPage.push(nicePageSize);
      updatedRowsPerPage.sort((a, b) => a - b);
    }
  }

  return updatedRowsPerPage;
};
