import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import FormButtons from '@/components/form/form-buttons';
import useQueryHelper from '@/hooks/use-query-helper';
import { getOrgPolicy, patchPolicy } from '@/lib/services/policy.service';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { getAllAgents } from '@/lib/services/agent.service';

const fields = ['assistants'];
const formModel = new PolicyModel();

const AgentsForm = () => {
  const { errorToast, toast } = useToast();
  const { canChangeAgents } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(values.id, values.propSlice(fields));
      setSubmitting(false);

      if (updated) {
        resetForm({ values });
        toast('The organization policy was updated');
        return;
      }

      errorToast('The organization policy was not updated');
    },
  });

  const orgPolicyQuery = useQuery([QueryKey.OrgPolicyView], async () => getOrgPolicy());

  const agentsQuery = useQuery([QueryKey.AgentsList], async () => getAllAgents());
  const { data: agents } = agentsQuery;
  const { showLoader: policyLoading } = useQueryHelper(orgPolicyQuery);
  const { showLoader: agentLoading } = useQueryHelper(agentsQuery);

  const agentOptions = useMemo(() => {
    return (agents || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [agents]);

  if (policyLoading || agentLoading) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={orgPolicyQuery}>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />
      <FormSection title="Assistant Assignment">
        <InputControl
          name="assistants.enabled"
          label="Assistants"
          formHandle={formHandle}
          readonly={!canChangeAgents}
          type="list-toggle"
          leftTitle="Available Assistants"
          rightTitle="Assigned Assistants"
          options={agentOptions}
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangeAgents} />
    </Form>
  );
};

export default AgentsForm;
