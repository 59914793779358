import { ComponentProps, FC, Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ConversationDrawer from '@/components/drawers/conversation';
import PublicServiceDrawer from '@/components/drawers/public-service';
import PrivateModelDrawer from '../components/drawers/private-model';
import AdminUserDrawer from '../components/drawers/admin-user';
import UserDrawer from '@/components/drawers/end-user';
import usePrior from './use-prior.hook';
import { DRAWER_ANIMATE_DURATION } from '../components/drawers/helpers';
import GroupDrawer from '@/components/drawers/group';
import UserEventDrawer from '@/components/drawers/user-event';
import ConnectorDrawer from '@/components/drawers/connector';
import ContextProviderDrawer from '@/components/drawers/context-provider';
import TelemetryDrawer from '@/components/drawers/telemetry';
import useUserControl from './use-user-control.hook';

type DrawerType =
  | 'context-provider'
  | 'conversation'
  | 'user-event'
  | 'public-service'
  | 'private-model'
  | 'admin-user'
  | 'end-user'
  | 'group'
  | 'connector'
  | 'telemetry';

type DrawerHook = {
  openDrawer: (addlProps?: unknown) => void;
  closeDrawer: () => void;
  DrawerEl: ReactNode;
};

const drawerMap: Map<DrawerType, FC> = new Map();
drawerMap.set('context-provider', ContextProviderDrawer);
drawerMap.set('conversation', ConversationDrawer);
drawerMap.set('user-event', UserEventDrawer);
drawerMap.set('public-service', PublicServiceDrawer);
drawerMap.set('private-model', PrivateModelDrawer);
drawerMap.set('admin-user', AdminUserDrawer);
drawerMap.set('end-user', UserDrawer);
drawerMap.set('group', GroupDrawer);
drawerMap.set('connector', ConnectorDrawer);
drawerMap.set('telemetry', TelemetryDrawer);

const useDrawer = (drawerType: DrawerType, props?: unknown): DrawerHook => {
  const DrawerComponent = drawerMap.get(drawerType) || Fragment;
  type DrawerProps = ComponentProps<typeof DrawerComponent>;

  const [drawerProps, setDrawerProps] = useState({
    ...(props || { onClose: () => null }),
  });

  const { checkLockedAndEmit } = useUserControl();

  const handleClose = useCallback(
    (force?: boolean) => {
      if (!force && checkLockedAndEmit()) {
        return false;
      }

      setOpen(false);

      drawerProps.onClose?.();
    },
    [checkLockedAndEmit, drawerProps]
  );

  /*
   * The reason we have separate state values for both open and mounted is because, for render optimization, and handling
   * changing drawer props, we want to mount and unmount the drawer when it opens and closes. But we also need to give the
   * nice open and close animations time to run. So on open, we first mount, wait for the drawer to render as closed,  and
   * then open. And on close, we first close, wait for the close animation to run, and then unmount.
   */

  const [open, setOpen] = useState(false);
  const priorOpen = usePrior(open);

  const [mounted, setMounted] = useState(false);
  const priorMounted = usePrior(mounted);

  useHotkeys(
    'esc',
    () => {
      if (!open) {
        return;
      }
      handleClose();
    },
    [handleClose, open]
  );

  // if the drawer becomes mounted, open it
  useEffect(() => {
    if (mounted && !priorMounted) {
      setOpen(true);
    }
  }, [mounted, priorMounted]);

  // if the drawer is closed, unmount it
  useEffect(() => {
    if (!open && priorOpen) {
      setTimeout(() => {
        setMounted(false);
      }, DRAWER_ANIMATE_DURATION + 200);
    }
  }, [open, priorOpen]);

  const compProps = {
    ...drawerProps,
    open,
    onClose: handleClose,
  } as DrawerProps;

  // @todo the problem is that since we are storing the onClose function in state, we are snapshotting the context value

  // we need to pull the onClose function out of state. ideally we should entirely remove the use of state in this hook

  return {
    openDrawer: (addlProps?: unknown) => {
      setDrawerProps((old) => ({
        ...old,
        ...(addlProps || {}),
      }));
      setMounted(true);
    },
    closeDrawer: (force?: boolean) => {
      handleClose(force);
    },
    DrawerEl: mounted ? <DrawerComponent {...compProps} /> : null,
  };
};

export default useDrawer;
