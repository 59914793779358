import Drawer, { DrawerProps } from '@/components/drawers';
import DrawerContent from '@/components/drawers/content';
import DrawerHeader from '@/components/drawers/header';
import DrawerButtons from '@/components/form/drawer-buttons';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useModal from '@/hooks/use-modal.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { UiOption, ViewState } from '@/lib/helpers';
import { PrivateModelModel } from '@/lib/models/private-model/private-model.model';
import {
  createPrivateModelFromAvailable,
  deletePrivateModel,
  getAvailablePrivateModels,
  getModelUsage,
  getPrivateModelById,
  ModelUsage,
  ModelUsageMap,
  patchPrivateModel,
} from '@/lib/services/private-model.service';
import { Box, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import DrawerToolbar from '../toolbar';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { AvailablePrivateModel } from '@/lib/models/available-private-model.model';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import { getPrivateModelConnectors } from '@/lib/services/connector.service';
import InlineToast from '@/components/toasts/inline';
import PrivateModelReadonlyView from './readonly-view';
import useFeature from '@/hooks/use-feature';
import { getTypedConnector } from '@/lib/models/connector';
import PrivateModelTestCard from './test-card';
import { ReadonlySection } from '@/components/form/readonly-view/section';

interface Props extends DrawerProps {
  id: string;
}
const formModel = new PrivateModelModel();

const getModelOpts = (availableModels: AvailablePrivateModel[]): UiOption[] => {
  if (!availableModels?.length) {
    return [];
  }

  const makeModelOpt = (model: AvailablePrivateModel, disabled = false): UiOption => ({
    value: model.modelId,
    label: `${model.name} (${model.modelId})`,
    disabled,
  });

  const supportedModelOpts = availableModels.map((model) => makeModelOpt(model));

  return [
    { label: 'Supported Models', value: 'supported', meta: { header: true } },
    ...supportedModelOpts,
  ];
};

export const PrivateModelDrawer: FC<Props> = ({ id, open, onClose, onChange, className }) => {
  const [modelId, setModelId] = useState(id || null);
  const isNew = !modelId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'edit' : 'view');
  const [connectorId, setConnectorId] = useState('');
  const { toast, errorToast } = useToast();
  const { openModal } = useModal();

  const { canChangePrivateModels } = useFeature();

  const connectorQuery = useQuery<ConnectorModel[]>([QueryKey.ConnectorModelsList], async () =>
    getPrivateModelConnectors()
  );

  const { data: availableConnectors = [] } = connectorQuery;
  const { showLoader: connectorsLoading } = useQueryHelper(connectorQuery);

  const modelConnector = useMemo(() => {
    const connector = availableConnectors.find(({ id }) => id === connectorId);

    if (!connector) {
      return new ConnectorModel();
    }

    return getTypedConnector(connector);
  }, [availableConnectors, connectorId]);

  const availableModelsQuery = useQuery<AvailablePrivateModel[]>(
    [QueryKey.PrivateModelsAvailable, modelConnector.id],
    async () => {
      if (!modelConnector.id || !modelConnector.canListAvailable) {
        return [];
      }

      const { rows: availableModels } = await getAvailablePrivateModels([connectorId]);

      return availableModels;
    },
    { enabled: !!modelConnector.id }
  );

  const { data: availableModels = [], refetch: refetchAvailableModels } = availableModelsQuery;
  const { showLoader: availableModelsLoading } = useQueryHelper(availableModelsQuery);

  const query = useQuery<[PrivateModelModel | null, ModelUsageMap]>(
    [QueryKey.PrivateModelViewMeta, modelId],
    async () => Promise.all([getPrivateModelById(modelId!), getModelUsage([modelId!])]),
    {
      enabled: !!modelId,
    }
  );

  const { showLoader: modelLoading } = useQueryHelper(query);
  const { data, refetch } = query;

  const [modelData, usageData] = data || [null, new Map<string, ModelUsage>()];
  const privateModel = modelData || new PrivateModelModel();

  let modelUsage: ModelUsage = { policies: [], agents: [], inUse: false };
  if (modelId && usageData?.has(modelId)) {
    modelUsage = usageData.get(modelId)!;
  }

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (values, { resetForm }) => {
      if (!modelConnector) {
        errorToast('Invalid connector');
        return;
      }

      if (!modelId) {
        const chosenModel = availableModels.find(({ modelId }) => modelId === values.modelId);

        if (!chosenModel) {
          errorToast('Invalid model');
          return;
        }

        const {
          inserted,
          id: newModelId,
          error,
        } = await createPrivateModelFromAvailable(values, modelConnector, chosenModel);

        if (!inserted || error) {
          errorToast(error || 'The private model was not created');
          return;
        }

        resetForm({ values });
        toast('The private model was created');
        setModelId(newModelId!);
        onChange && onChange();
        setMode('view');
        return;
      }

      const { updated, error } = await patchPrivateModel(values.id, values.name);

      if (updated) {
        resetForm({ values });
        toast('The private model was updated');
        refetch();
        onChange && onChange();
        setMode('view');
        return;
      }

      errorToast(error || 'The private model was not updated');
    },
  });

  const { handleSubmit, canSubmit, isSubmitting, loadData } = formHandle;
  const inWriteMode = mode === 'edit';

  const handleCancel = () => {
    if (isNew) {
      onClose(true);
      return;
    }
    setMode('view');
    loadData(privateModel);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete ${privateModel.name}?`,
      onClose: (confirm: boolean) => {
        if (confirm) {
          deletePrivateModel(modelId!).then(({ deleted, error }) => {
            if (deleted) {
              setModelId('');
              onClose();
              toast('The model was deleted');
              onChange && onChange();
              return;
            }

            errorToast(error || 'The model was not deleted');
          });
        }
      },
    });
  };

  const handleChangeConnector = (value: string) => {
    setConnectorId(value);
    refetchAvailableModels();
  };

  const handleTest = () => {
    refetch();
    onChange?.();
  };

  const hasConnector = !!modelConnector.id;
  const hasUsage = modelUsage.inUse;
  const canDelete = !hasUsage;
  const showChildren = !connectorsLoading && !modelLoading;
  const showModelSelect = modelConnector.canListAvailable;

  const modelOpts: UiOption[] = hasConnector ? getModelOpts(availableModels) : [];
  const hasModelOpts = !!modelOpts.filter(({ meta }) => !meta?.header).length;
  const showModelWarning = hasConnector && !availableModelsLoading && !hasModelOpts;

  const connectorOpts: UiOption[] = availableConnectors.map(({ id, name, status }) => ({
    label: name,
    value: id,
    disabled: status !== 'active',
  }));

  useEffect(() => {
    if (data) {
      const [model] = data;
      setConnectorId(model?.connectorId || '');
    }
  }, [data]);

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      className={className}
      query={query}
      loading={connectorsLoading || modelLoading}
    >
      <DrawerHeader onClose={handleClose}>
        {isNew ? 'Add Private Model' : privateModel.name}
      </DrawerHeader>

      <DrawerContent>
        {!isNew && canChangePrivateModels && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={!inWriteMode}
            canDelete={canDelete}
            deleteTooltip={!canDelete ? 'You cannot delete a model that is in use' : ''}
          />
        )}
        <Stack>
          {!inWriteMode && showChildren && (
            <PrivateModelReadonlyView
              model={privateModel}
              connector={modelConnector}
              usage={modelUsage}
            />
          )}

          {inWriteMode && showChildren && (
            <Form formHandle={formHandle} query={query}>
              <InputControl name="name" label="Name" formHandle={formHandle} autoFocus />

              <InputControl
                name="connectorId"
                label="Connector"
                formHandle={formHandle}
                onChange={handleChangeConnector}
                type="select"
                options={connectorOpts}
                readonly={!isNew}
              />

              {showModelSelect && (
                <>
                  <InputControl
                    name="modelId"
                    label="Model"
                    formHandle={formHandle}
                    type="select"
                    options={modelOpts}
                    disabled={!hasConnector || showModelWarning}
                    readonly={!isNew}
                  />

                  {showModelWarning && (
                    <InlineToast
                      hideIcon
                      level="warning"
                      message="No models available for this connector"
                    />
                  )}
                </>
              )}

              {!showModelSelect && (
                <InputControl
                  name="modelId"
                  label="Model ID"
                  formHandle={formHandle}
                  disabled={!hasConnector}
                  readonly={!isNew}
                />
              )}

              <DrawerButtons
                submit
                cancel
                isNew={isNew}
                canSubmit={canSubmit}
                canCancel={!isSubmitting}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                formHandle={formHandle}
              />
            </Form>
          )}
        </Stack>

        {!isNew && !inWriteMode && (
          <Box mt={2}>
            <ReadonlySection label="Testing" />
            <PrivateModelTestCard model={privateModel} onTest={handleTest} />
          </Box>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default PrivateModelDrawer;
