import { Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import {
  DateFormat,
  DateRangeValue,
  downloadCsv,
  getNiceDate,
  getTimestamp,
  JsonObject,
  UiOption,
} from '../helpers';
import { axiosClient as spApiClient, create, deleteApi, get, patch, post } from './sp-api.service';
import { CreateTelemetryModel, TelemetryModel } from '../models/telemetry/telemetry.model';
import { CreateResponse, DEFAULT_SERVICE_ERROR, DeleteResponse, PatchResponse } from '.';
import { AxiosError } from 'axios';
import { orderBy } from 'lodash';
import { endOfDay, startOfDay } from 'date-fns';

type TelemetryListResponse = {
  count: number;
  currentPage: number;
  data: JsonObject[];
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalPages: number;
};

export type TelemetryTestReport = {
  accessVerified: boolean;
  found: boolean;
  canWrite: boolean;
  messages: string[];
};

type SearchFilter = {
  keyword?: string;
  users?: string[];
  decisions?: number[];
  violations?: string[];
  model?: string;
  date?: {
    start?: number;
    end?: number;
  };
  riskScore?: string[];
};

export const getTelemetry = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<TelemetryModel>> => {
  const result = (await get('/telemetry/telemetry-destinations', {
    page: 1,
    pageSize: 100,
  })) as TelemetryListResponse;

  if (!Array.isArray(result?.data)) {
    return {
      page,
      pageSize,
      rows: [],
      total: 0,
    };
  }

  let rows: TelemetryModel[] = result.data.map((data) => new TelemetryModel(data));

  if (dtFilter?.keyword) {
    const safeKeyword = String(dtFilter?.keyword).toLowerCase();
    rows = rows.filter(({ displayName }) => displayName.toLowerCase().includes(safeKeyword));
  }

  // Sort
  if (dtSort) {
    const { columnName, direction } = dtSort;
    rows = orderBy(rows, [columnName], [direction]);
  }

  // Paginate
  const startIndex = page * pageSize;
  rows = rows.slice(startIndex, startIndex + pageSize);

  return {
    page,
    pageSize,
    rows,
    total: result.count,
  };
};

export const createTelemetry = async (telemetry: TelemetryModel): Promise<CreateResponse> => {
  const createModel = new CreateTelemetryModel(telemetry);

  const response = await create(
    '/telemetry/telemetry-destinations',
    createModel._props,
    handleCreateErrorResponse
  );

  if (!response?.created) {
    return response;
  }

  response.model = new TelemetryModel(response.data);

  await testTelemetry(response.model.id);

  return response;
};

export const patchTelemetry = async (telemetry: TelemetryModel): Promise<PatchResponse> => {
  const createModel = new CreateTelemetryModel(telemetry);

  const response = await patch(
    `/telemetry/telemetry-destinations/${telemetry.id}`,
    createModel._props,
    handlePatchErrorResponse
  );

  if (!response?.patched) {
    return response;
  }

  await testTelemetry(telemetry.id);

  return response;
};

export const getTelemetryById = async (telemetryId: string): Promise<TelemetryModel | null> => {
  const response = await get(`/telemetry/telemetry-destinations/${telemetryId}`);
  return response ? new TelemetryModel(response as JsonObject) : null;
};

export const deleteTelemetry = async (telemetryId: string): Promise<DeleteResponse> => {
  return deleteApi(`/telemetry/telemetry-destinations/${telemetryId}`);
};

export const testTelemetry = async (telemetryId: string): Promise<TelemetryTestReport | null> => {
  const response = await post(`/telemetry/telemetry-destinations/${telemetryId}/verify-access`, {});

  const { report } = (response || {}) as { report: TelemetryTestReport };

  return report || null;
};

export const downloadTelemetry = async (dtSort: DtSort, dtFilter?: DtFilter): Promise<boolean> => {
  const filterParams: SearchFilter = {};

  if (dtFilter) {
    const { keyword, values } = dtFilter;

    if (keyword) {
      filterParams.keyword = keyword;
    }

    values.forEach(({ columnName, value }) => {
      switch (columnName) {
        case 'startTime':
          {
            const [startTime, endTime] = value as DateRangeValue;
            const startTimestamp = startTime ? getTimestamp(startOfDay(startTime), true) : null;
            const endTimestamp = endTime ? getTimestamp(endOfDay(endTime), true) : null;

            if (startTimestamp || endTimestamp) {
              const dateFilter: { start?: number; end?: number } = {};

              if (startTimestamp) {
                dateFilter.start = startTimestamp;
              }

              if (endTimestamp) {
                dateFilter.end = endTimestamp;
              }

              filterParams.date = dateFilter;
            }
          }
          break;
        case 'action':
          {
            const safeValue = Array.isArray(value) ? value : [value];
            filterParams.decisions = safeValue.map((val) => Number(val));
          }
          break;
        case 'model':
          filterParams.model = value as string;
          break;
        case 'violation':
          filterParams.violations = value as string[];
          break;
        case 'user':
          if (!filterParams.users) {
            filterParams.users = [];
          }

          filterParams.users.push((value as UiOption).value);
          break;
        case 'riskScore':
          filterParams.riskScore = value as string[];
          break;
      }
    });
  }

  const response = await spApiClient.post(
    '/telemetry/search',
    {
      sort: [{ [dtSort.columnName]: dtSort.direction }],
      match: filterParams,
    },
    { headers: { Accept: 'text/csv' } }
  );

  if (!response?.data) {
    return false;
  }

  downloadCsv(
    response.data as string,
    `user-events-export-${getNiceDate(new Date(), DateFormat.NOTIME).formatted}.csv`
  );

  return true;
};

const handleCreateErrorResponse = (error: AxiosError): CreateResponse => {
  if (!error.response) {
    return { created: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response as { status: number; data: JsonObject };

  if (status === 400 && Array.isArray(data?.errors)) {
    return {
      created: false,
      fieldErrors: data?.errors.map(({ field, rule }) => ({
        msg: rule as string,
        path: field as string,
        value: '',
      })),
    };
  }

  return { created: false, error: DEFAULT_SERVICE_ERROR };
};

const handlePatchErrorResponse = (error: AxiosError): PatchResponse => {
  if (!error.response) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response as { status: number; data: JsonObject };

  if (status === 400 && Array.isArray(data?.errors)) {
    return {
      patched: false,
      fieldErrors: data?.errors.map(({ field, rule }) => ({
        msg: rule as string,
        path: field as string,
        value: '',
      })),
    };
  }

  return { patched: false, error: DEFAULT_SERVICE_ERROR };
};
