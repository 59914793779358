import { FC, useEffect } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import Page, { PageProps } from '@/components/page';
import { AuthProvider } from '@/lib/services/auth.service';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import Environment from '@/lib/environment';
import { createUseStyles } from 'react-jss';
import useAppData from '@/hooks/use-app-data.hook';
import { AppDataQueryKey } from '@/lib/query-client';
import { OrgBasicInfo } from '@/lib/models/org.model';
import Text from '@/components/text';
import { DateFormat, getNiceDate } from '@/lib/helpers';

const { DASHBOARD_ID, DASHBOARD_URL } = Environment;

const useStyles = createUseStyles((theme: Theme) => ({
  '@global': {
    '#print-header': {
      display: 'none',
    },
    '@media print': {
      '#app-sidebar': {
        display: 'none !important',
      },
      '#print-header': {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.modal,
        width: '100%',
        height: 64,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[200],
        paddingTop: 20,
        boxSizing: 'border-box',
      },
      body: {
        overflow: 'auto !important',
        width: '100%',
      },
      main: {
        overflow: 'auto !important',
        height: 'inherit !important',
        width: '100% !important',
        '& > :nth-child(2)': {
          height: 'inherit !important',
          '& > div': {
            margin: 0,
            padding: 0,
            maxWidth: '100%',
          },
        },
      },
      '#dashboard': {
        position: 'absolute',
        top: 64,
        left: -24,
        pageBreakInside: 'avoid',
      },
    },
  },
}));

const InsightsPage: FC<PageProps> = () => {
  useStyles();

  const { data } = useAppData<OrgBasicInfo>(AppDataQueryKey.OrgBasicInfo);
  const { name } = data || {};

  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: DASHBOARD_URL,
      getUserToken: () => AuthProvider.token,
      showAttribution: false,
      background: 'transparent',
      autoRefresh: true,
      maxDataAge: 28800,
      filter: {
        timestamp: {
          $gte: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), // Last 30 days
        },
      },
      height: 4000,
      heightMode: 'fixed',
      widthMode: 'scale',
    });
    const dashboard = sdk.createDashboard({
      dashboardId: DASHBOARD_ID,
    });
    const dashboardElement = document.getElementById('dashboard');
    if (dashboardElement) {
      dashboard.render(dashboardElement);
    }
  }, []);
  return (
    <Page title="Insights">
      <Box id="print-header">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text bold size="large">
            GenAI Insights Report: {name}
          </Text>
          <Text>{getNiceDate(new Date(), DateFormat.FRIEND).formatted}</Text>
        </Stack>
      </Box>
      <Box id="dashboard" height={4000} />
    </Page>
  );
};

export default InsightsPage;
