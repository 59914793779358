import { ReactNode, FC } from 'react';
import { JsonObject } from '@/lib/helpers';
import EventEmitter from 'eventemitter3';
import { EmitterContext, EmitterEventType, EmitterListener } from './helpers';

interface Props {
  children: ReactNode;
}

const EmitterBus = new EventEmitter();
const listeners: Record<EmitterEventType, EmitterListener[]> = {
  'user-control-locked': [],
};

export const EmitterProvider: FC<Props> = ({ children }) => {
  const subscribe = (
    types: EmitterEventType | EmitterEventType[],
    listenerFn: (data?: JsonObject) => void
  ) => {
    (Array.isArray(types) ? types : [types]).forEach((type) => {
      const exists = listeners[type].some((existingListener) => existingListener === listenerFn);
      if (exists) {
        return;
      }

      EmitterBus.on(type, listenerFn);
      listeners[type].push(listenerFn);
    });
  };

  const unsubscribe = (
    types: EmitterEventType | EmitterEventType[],
    listenerFn: (data?: JsonObject) => void
  ) => {
    (Array.isArray(types) ? types : [types]).forEach((type) => {
      const listenerIndex = listeners[type].findIndex(
        (existingListener) => existingListener === listenerFn
      );
      if (listenerIndex > -1) {
        listeners[type].splice(listenerIndex, 1);
        EmitterBus.removeListener(type, listenerFn);
      }
    });
  };

  const emit = (type: EmitterEventType, data?: JsonObject) => {
    EmitterBus.emit(type, data);
  };

  return (
    <EmitterContext.Provider value={{ emit, subscribe, unsubscribe }}>
      {children}
    </EmitterContext.Provider>
  );
};
