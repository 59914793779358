import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import { ConnectorModel } from './connector.model';

export type ProxyConfig = {
  orgAuthEnabled: boolean;
  pacFileUrl: string;
};

export class ProxyConnectorModel extends ConnectorModel {
  config: ProxyConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'proxy';
    this.typeLabel = 'Proxy';

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: false,
      ingress: true,
    };

    this.config = (data.config as ProxyConfig) || { orgAuthEnabled: false, pacFileUrl: '' };
  }
}
