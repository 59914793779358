import AccordionButton from '@/components/accordion-button';
import Button from '@/components/button';
import Card from '@/components/card';
import CardHeader from '@/components/card/card-header';
import CardRow from '@/components/card/card-row';
import NoneChip from '@/components/chip/none-chip';
import StatusChip from '@/components/chip/status-chip';
import Text from '@/components/text';
import useToast from '@/hooks/use-toast.hook';
import { LoadState } from '@/lib/helpers';
import { PrivateModelModel } from '@/lib/models/private-model/private-model.model';
import {
  PrivateModelTestReport,
  testPrivateModelInference,
} from '@/lib/services/private-model.service';
import { Box, Stack } from '@mui/material';
import { FC, useState } from 'react';

interface Props {
  model: PrivateModelModel;
  onTest: () => void;
}

const PrivateModelTestCard: FC<Props> = ({ model, onTest }) => {
  const [testLoadState, setTestLoadState] = useState<LoadState>('unloaded');
  const [report, setReport] = useState<PrivateModelTestReport>({
    inferenceSuccessful: false,
    messages: [],
  });

  const { errorToast } = useToast();

  const handleTestSettings = () => {
    setTestLoadState('loading');

    testPrivateModelInference(model.id).then((report) => {
      if (!report) {
        setTestLoadState('unloaded');
        errorToast('Unable to run test. Please check that the connector is active.');
        return;
      }

      setTestLoadState('loaded');
      setReport(report);
      onTest();
    });
  };

  const loaded = testLoadState === 'loaded';
  const { messages, inferenceSuccessful } = report;
  const hasMessages = !!messages.length;

  return (
    <Card>
      <CardHeader title="Inference Test" icon="test">
        <Button
          label="Run Test"
          onClick={handleTestSettings}
          type="submit"
          loading={testLoadState === 'loading'}
          size="small"
        />
      </CardHeader>
      <Stack gap={1}>
        <CardRow>
          <Stack direction="row" justifyContent="space-between">
            <Text>Inference successful</Text>
            {!loaded && <NoneChip notAvailable />}
            {loaded && <StatusChip value={inferenceSuccessful ? 'yes' : 'no'} size="small" />}
          </Stack>
        </CardRow>

        <AccordionButton title="Details">
          {!hasMessages && <NoneChip />}
          {hasMessages &&
            messages.map((message, index) => (
              <Box mb={1} key={index}>
                <Text size="small">{message}</Text>
              </Box>
            ))}
        </AccordionButton>
      </Stack>
    </Card>
  );
};

export default PrivateModelTestCard;
