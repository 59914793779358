import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import BraveAPIIcon from '@/assets/connector-icons/brave-api.png';
import { ConnectorModel } from './connector.model';

export type BraveAPIConfig = {
  apiKey: string;
};

export class BraveAPIConnectorModel extends ConnectorModel {
  config: BraveAPIConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'brave-search';
    this.typeLabel = 'Brave Search API';
    this.iconSVG = BraveAPIIcon;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: true,
      ingress: false,
    };

    // the only config prop is apiKey, and that is kept in secret storage, so no need to load from props
    this.config = {
      apiKey: '',
    };

    this.configSecrets = ['apiKey'];

    this.contextProviderTypes = ['customer-web-brave-search'];
  }
}
