import AI21Icon from '@/assets/model-icons/AI21.svg';
import AnthropicClaudeIcon from '@/assets/model-icons/anthropic-claude.svg';
import AWSIcon from '@/assets/model-icons/aws.svg';
import CohereCommandIcon from '@/assets/model-icons/cohere-command.svg';
import MetaLlamaIcon from '@/assets/model-icons/meta-llama2.svg';
import MistralIcon from '@/assets/model-icons/mistral.svg';
import DeepSeekIcon from '@/assets/model-icons/deepseek.svg';
import OpenAIIcon from '@/assets/model-icons/openai.svg';
import GeminiIcon from '@/assets/connector-icons/google-gemini.png';
import { FC } from 'react';
import Icon from '@/components/icon';

export type ModelIconId =
  | 'ai21'
  | 'amazon'
  | 'anthropic'
  | 'cohere'
  | 'deepseek'
  | 'gemini'
  | 'gpt'
  | 'llama'
  | 'mistral'
  | 'o1';

interface Props {
  iconId: ModelIconId;
  size?: 'small' | 'medium';
}

const ModelIconMap: Map<ModelIconId, string> = new Map([
  ['ai21', AI21Icon],
  ['anthropic', AnthropicClaudeIcon],
  ['amazon', AWSIcon],
  ['cohere', CohereCommandIcon],
  ['deepseek', DeepSeekIcon],
  ['gemini', GeminiIcon],
  ['gpt', OpenAIIcon],
  ['llama', MetaLlamaIcon],
  ['mistral', MistralIcon],
  ['o1', OpenAIIcon],
]);

const ModelIcon: FC<Props> = ({ iconId, size = 'medium' }) => {
  let compIconId = iconId;

  if (!ModelIconMap.has(compIconId) && iconId.includes('.')) {
    compIconId = iconId.split('.').shift() as ModelIconId;
  }

  if (!ModelIconMap.has(compIconId)) {
    console.warn('unknown model icon', iconId);
    return <Icon name="model-training" />;
  }

  return <img src={ModelIconMap.get(compIconId)} alt={iconId} width={size === 'small' ? 20 : 60} />;
};

export default ModelIcon;
