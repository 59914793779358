import { CreateResponse, DeleteResponse, UpdateResponse } from '.';
import { JsonObject } from '../helpers';
import { AgentModel } from '../models/agent.model';
import { PolicyModel } from '../models/policy.model';
import { getPoliciesByAgentIds } from './policy.service';
import { deleteApi, get, post, update } from './sp-api.service';

export type AgentUsage = { policies: PolicyModel[]; inUse: boolean };

export const createAgent = async (agent: AgentModel): Promise<CreateResponse<AgentModel>> => {
  const response = await post('/shortcut/assistant', agent._props);

  if (!response?.insertedId || !response?.assistant) {
    return { created: false, error: 'Unable to create assistant' };
  }

  return {
    created: true,
    model: new AgentModel({ ...(response.assistant as JsonObject) }),
  };
};

export const updateAgent = async (agent: AgentModel): Promise<UpdateResponse> => {
  return update(`/shortcut/assistant/${agent.id}`, agent._props);
};

export const deleteAgent = async (agentId: string): Promise<DeleteResponse> => {
  const response = (await deleteApi(`/shortcut/assistant/${agentId}`)) as JsonObject;

  if (response?.deleted) {
    return { deleted: true };
  }

  return { deleted: false, error: 'Unable to delete assistant' };
};

export const getAgentById = async (agentId: string): Promise<AgentModel | null> => {
  const response = (await get('/shortcut/assistant', { assistantIds: agentId })) as {
    assistants: JsonObject[];
  };
  return response?.assistants?.length ? new AgentModel(response.assistants[0]) : null;
};

export const getAllAgents = async (): Promise<AgentModel[]> => {
  const response = (await get('/shortcut/assistant')) as {
    assistants: JsonObject[];
  };

  if (!Array.isArray(response?.assistants)) {
    return [];
  }

  return response.assistants.map((data) => new AgentModel(data));
};

export const getAgentsByIds = async (agentIds: string[]): Promise<AgentModel[]> => {
  return getAllAgents().then((agents) => agents.filter(({ id }) => agentIds.includes(id)));
};

export const getAgentsByModelIds = async (modelIds: string[]): Promise<AgentModel[]> => {
  return getAllAgents().then((agents) =>
    agents.filter(({ privateModelId }) => modelIds.includes(privateModelId))
  );
};

export const getAgentsByDatasourceIds = async (datasourceIds: string[]): Promise<AgentModel[]> => {
  return getAllAgents().then((agents) =>
    agents.filter(({ dataSourceIds: agentDatasourceIds }) =>
      agentDatasourceIds.some((dsId) => datasourceIds.includes(dsId))
    )
  );
};

export const getAgentUsage = async (agentIds: string[]): Promise<Map<string, AgentUsage>> => {
  const policies = await getPoliciesByAgentIds(agentIds);

  const usageMap: Map<string, AgentUsage> = new Map();
  agentIds.forEach((agentId) => {
    const usage = {
      policies: policies.filter(({ assistants }) => assistants.enabled.includes(agentId)),
    };
    usageMap.set(agentId, { ...usage, inUse: !!usage.policies.length });
  });

  return usageMap;
};
