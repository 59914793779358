import { AlertColor } from '@mui/material';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { object, ObjectSchema } from 'yup';
import useEmitter from './use-emitter';
import useUserControl from './use-user-control.hook';

export type FormMessage = { level: AlertColor; content: string; attention?: boolean };

export type FormHandle<T extends FormikValues> = ReturnType<typeof useFormik<T>> & {
  validationSchema: ObjectSchema<object>;
  canSubmit: boolean;
  canCancel: boolean;
  formMessage: FormMessage | null;
  setFormMessage: (formMessage: FormMessage) => void;
  loadData: (values: FormikValues) => void;
  handleCancel: () => void;
  updateValidationSchema: (validationSchema: ObjectSchema<object>) => void;
};

type FormHandleOpts = {
  dirtyLockDisabled?: boolean;
};

const useFormHandle = <T extends FormikValues>(
  config: FormikConfig<T>,
  opts?: FormHandleOpts
): FormHandle<T> => {
  const { subscribe, unsubscribe } = useEmitter();
  const { toggleLocked } = useUserControl();

  const { dirtyLockDisabled = false } = opts || {};

  const [formMessage, setFormMessage] = useState<FormMessage | null>({
    level: 'info',
    content: '',
  });

  const [validationSchema, setValidationSchema] = useState<ObjectSchema<object>>(
    (config.validationSchema || object({})) as ObjectSchema<object>
  );

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    ...config,
    validationSchema,
  });

  const { isSubmitting, dirty, isValid, validateOnChange, resetForm, initialValues } = formik;

  const canSubmit = !isSubmitting && dirty && (!validateOnChange || isValid);
  const canCancel = !isSubmitting && dirty;

  const loadData = useCallback(
    (values: T) => {
      resetForm({ values });
    },
    [resetForm]
  );

  const handleCancel = useCallback(() => {
    loadData(initialValues);
  }, [loadData, initialValues]);

  const updateValidationSchema = useCallback((updatedSchema: ObjectSchema<object>) => {
    setValidationSchema(updatedSchema);
  }, []);

  const handleNavBlock = useCallback(() => {
    /*
     * In order to ensure that the form message (i.e. FormButtons -> InlineToast) is scrolled into view and has the attention animation
     * applied, it must be unmounted and then mounted, so set the message to null to unmount, then wait for a render cycle and mount
     */

    setFormMessage(null);

    setTimeout(() => {
      setFormMessage({
        level: 'warning',
        content: 'Please save changes or cancel before leaving the form.',
        attention: true,
      });
    }, 0);
  }, []);

  // block navigation when a form is dirty
  useEffect(() => {
    if (dirtyLockDisabled) {
      return;
    }
    toggleLocked(dirty);
    if (!dirty) {
      setFormMessage(null);
    }
  }, [toggleLocked, dirty, dirtyLockDisabled]);

  useEffect(() => {
    subscribe('user-control-locked', handleNavBlock);
    return () => {
      unsubscribe('user-control-locked', handleNavBlock);
    };
  }, [subscribe, unsubscribe, handleNavBlock]);

  // try to ensure we don't leave the nav locked when the form component unmounts
  useEffect(() => {
    return () => {
      toggleLocked(false);
    };
  }, [toggleLocked]);

  return {
    ...formik,
    validationSchema,
    canSubmit,
    formMessage,
    setFormMessage,
    loadData,
    handleCancel,
    canCancel,
    updateValidationSchema,
  };
};

export default useFormHandle;
