import {
  redirect,
  Navigate,
  createRoute,
  createRouter,
  createRootRoute,
} from '@tanstack/react-router';
import App from '../App';
import InsightsPage from '../pages/insights';
import OrgSettingsPage from '../pages/config/org-settings';
import NotFoundPage from '../pages/not-found';
import AdminUsersPage from '../pages/admin/users';
import UsersPage from '../pages/config/users';
import { AuthProvider, userCan } from '../lib/services/auth.service';
import PrivateModelsPage from '@/pages/config/private-models';
import ContextProvidersPage from '@/pages/config/context-providers';
import GroupsPage from '@/pages/config/groups';
import LoginPage from '@/pages/login';
import ErrorPage from '@/pages/error';
import OrgPolicyPage from '@/pages/config/org-policy';
import PoliciesPage from '@/pages/config/policies';
import PolicyViewPage from '@/pages/config/policies/view';
import PublicServicesPage from '@/pages/config/public-services';
import UserEventsPage from '@/pages/user-events';
import ConnectorsPage from '@/pages/config/connectors';
import LogoutPage from '@/pages/logout';
import AgentsPage from '@/pages/config/agents';
import AgentViewPage from '@/pages/config/agents/view';
import TelemetryPage from '@/pages/config/telemetry';

const rootRoute = createRootRoute({
  component: App,
});

/*
 * This check is technically a failsafe because of the authentication check that happens
 * in @/src/context/app/index.tsx. Read the comment in that file in the primary useEffect
 * hook for more details.
 */
const requireAuth = () => {
  if (!AuthProvider.user) {
    throw redirect({
      to: '/login',
    });
  }
};

const requireManageAdmins = () => {
  if (!userCan(AuthProvider.user, 'manage-admins')) {
    throw redirect({
      to: '/user-events',
    });
  }
};

const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: () => <Navigate to="/user-events" />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/insights',
    component: InsightsPage,
    beforeLoad: requireAuth,
  }),
  // @todo old insights route. remove after ensuring no recent usage
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/home',
    component: () => <Navigate to="/insights" />,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/public-services',
    component: PublicServicesPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/private-models',
    component: PrivateModelsPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/context-sources',
    component: () => <Navigate to="/config/data-sources" />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/data-sources',
    component: ContextProvidersPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/assistants',
    component: AgentsPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/assistants/$agentId',
    component: AgentViewPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/telemetry-destinations',
    component: TelemetryPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/user-events',
    component: UserEventsPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/org-settings',
    component: OrgSettingsPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/groups',
    component: GroupsPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/group-policies',
    component: PoliciesPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/default-policy',
    component: OrgPolicyPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/group-policies/$policyId',
    component: PolicyViewPage,
    beforeLoad: requireAuth,
  }),
  // @tod old group policies route. remove after ensuring no recent usage
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/policies/$policyId',
    beforeLoad: ({ params }) => {
      throw redirect({
        to: '/config/group-policies/$policyId',
        params,
        search: true,
      });
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/admin-users',
    component: AdminUsersPage,
    beforeLoad: requireManageAdmins,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/end-users',
    component: UsersPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/login',
    component: LoginPage,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/logout',
    component: LogoutPage,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/config/connectors',
    component: ConnectorsPage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/error',
    component: ErrorPage,
    beforeLoad: () => ({ layout: 'public' }),
    validateSearch: ({ code }: Record<string, unknown>): { code?: number } => {
      return {
        code: code ? Number(code) : undefined,
      };
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '*',
    component: NotFoundPage,
  }),
];

const routeTree = rootRoute.addChildren(routes);

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default router;
