import { UiOption } from '../../helpers';
import { UserEventDataCategory } from './data-categories';

export type UserEventRole = 'context' | 'user' | 'assistant';

export type SdPosition = { startPos: number; endPos: number; type: string; score: number };

export type SensitiveData = { detected: boolean; detections: SdPosition[][] };

export type IntentScore = {
  name: string;
  confidence: number;
  actionName: string;
  departmentName: string;
};

export type UserEventIntent = {
  requestViolation: boolean;
  responseViolation: boolean;
  request: IntentScore[];
  response: IntentScore[];
};

export type UserClaim = {
  userId: string;
  email: string;
  name: string;
  role: string;
  orgId: string;
  groups: string[];
  spUserId: string;
};

export type ClientAddr = {
  remoteAddr: string;
  xForwardedFor: string;
  xRealIp: string;
};

export type PolicyDecision = {
  decision: UserEventAction;
  label: string;
  code: number;
  codeLabel: string;
  violations: {
    sensitiveData: boolean;
    intent: boolean;
    access: boolean;
    input: boolean;
    output: boolean;
  };
  isGroupPolicy: boolean;
  groupId: string;
};

// we should never see 0,3,6
export enum UserEventAction {
  CONTINUE = 0,
  ALLOW = 1,
  BLOCK = 2,
  DROP = 3,
  PORTAL = 4,
  REROUTE = 5,
  PASSTHROUGH = 6,
  ERROR = 7,
  DOWNTIME = 8,
  HTTPERROR = 9,
  AUTH = 10,
  INTERCEPTAUTH = 11,
  INLINEBLOCK = 12,
  REDACT = 13,
}

type ShortcutInputType = 'text' | 'textarea' | 'range' | 'switch' | 'select' | 'select-multi';

type ShortcutValue = string | string[];

type ShortcutStep = {
  name: string;
  title: string;
  description: string;
  inputType: ShortcutInputType;
  options?: UiOption[];
};

export type ShortcutMessage = {
  id: string;
  name: string;
  instructions: string;
  steps: ShortcutStep[];
  values: ShortcutValue[];
};

export type ContextDetails = {
  name: string;
  origin: 'user' | 'enterprise';
  source: string;
  purpose?: string;
  summary?: string;
  type: string; // @todo file|context-datasource?
};

export type UserEventMessage = {
  requestId: string;
  role: UserEventRole;
  content: string;
  origContent?: string;
  timestamp: number;
  shortcut: ShortcutMessage;
  contextDetails: ContextDetails;
  violations: {
    sensitiveData: boolean;
    intent: boolean;
    access: boolean;
    input: boolean;
    output: boolean;
  };
  sensitiveDataDetections: SdPosition[];
};

export type FirewallDecision = {
  action: string;
  target: { name: string };
};

export type PromptClassification = {
  request: RiskAssessment;
};

export type RiskLevel =
  | 'low'
  | 'medium'
  | 'high'
  | 'critical'
  | 'public'
  | 'internal'
  | 'confidential'
  | 'unknown';

export type RiskAssessment = {
  riskAssessment: {
    publicServiceRisk: RiskLevel;
    sensitiveDataRisk: RiskLevel;
    riskFloor: RiskLevel;
    overallRiskScore: RiskLevel;
  };
  input: {
    intents: string[];
    intentRisk: RiskLevel;
    roles: string[];
    dataCategories?: UserEventDataCategory[];
    dataSensitivity: RiskLevel;
    aiProviderRisk: RiskLevel;
    inputAssessment: RiskLevel;
  };
  inputError: string | null;
  output: {
    anticipatedContentSensitivity: RiskLevel;
    complianceRiskAssessment: RiskLevel;
    dataCategories?: UserEventDataCategory[];
    harmfulContentRisk: RiskLevel;
    biasAndFairnessRisk: RiskLevel;
    roleAlignment: string;
    outputAssessment: RiskLevel;
  };
  outputError: string | null;
};
