import { FC } from 'react';
import { useQuery } from 'react-query';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import useQueryHelper from '@/hooks/use-query-helper';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import {
  deleteAgent,
  getAgentById,
  getAgentUsage,
  updateAgent,
} from '@/lib/services/agent.service';
import { AgentModel } from '@/lib/models/agent.model';
import { Box } from '@mui/material';
import FormButtons from '@/components/form/form-buttons';
import { useNavigate } from '@tanstack/react-router';

const fields = ['name', 'description'];
const formModel = new AgentModel();

interface Props {
  agentId: string;
}

const AgentAdminProfileForm: FC<Props> = ({ agentId }) => {
  const navigate = useNavigate();
  const { errorToast, toast } = useToast();
  const { canChangeAgents } = useFeature();

  const query = useQuery([QueryKey.AgentView, agentId], async () => getAgentById(agentId));
  const { data: agent } = query;
  const { showLoader: agentLoading } = useQueryHelper(query);

  const usageQuery = useQuery([QueryKey.AgentUsage, agentId], async () => getAgentUsage([agentId]));
  const { data: agentUsageMap } = usageQuery;
  const { showLoader: usageLoading } = useQueryHelper(usageQuery);

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await updateAgent(values);
      setSubmitting(false);

      if (updated) {
        resetForm({ values });
        toast('The assistant was updated');
        return;
      }

      errorToast('The assistant was not updated');
    },
  });

  const handleDelete = () => {
    deleteAgent(agentId).then(({ deleted, error }) => {
      if (deleted) {
        toast('The assistant was deleted');
        navigate({ to: '/config/assistants' });
        return;
      }

      errorToast(error || 'The assistant was not deleted');
    });
  };

  if (agentLoading || usageLoading) {
    return;
  }

  const agentUsage = agentUsageMap?.get(agentId) || { policies: [], inUse: false };
  const disableDelete = agentUsage.inUse;

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />
      <FormSection title="Admin Profile Settings">
        <Box mb={2}>
          The admin profile name and description are used exclusively in the Admin to identify
          assistants, and can differ from the title and description shown to users of the Portal.
        </Box>
        <InputControl
          name="name"
          label="Name"
          formHandle={formHandle}
          readonly={!canChangeAgents}
        />
        <InputControl
          name="description"
          label="Description"
          formHandle={formHandle}
          readonly={!canChangeAgents}
          type="textarea"
        />
      </FormSection>

      <FormButtons
        formHandle={formHandle}
        readonly={!canChangeAgents}
        onDelete={handleDelete}
        deleteName={agent?.name}
        disableDelete={disableDelete}
        disableDeleteTooltip="You cannot delete an assistant that is in use"
      />
    </Form>
  );
};

export default AgentAdminProfileForm;
