import { object, string } from 'yup';
import { BaseModel, CreateBaseModel } from './base.model';
import { isValidEmail, JsonObject, UiOption } from '../helpers';

export type AdminUserRole = 'org:owner' | 'org:admin' | 'org:auditor';

export const AdminRoleLabels: Record<AdminUserRole, string> = {
  'org:owner': 'Owner',
  'org:admin': 'Admin',
  'org:auditor': 'Auditor',
};

export const AdminRoleOptions: UiOption[] = Object.keys(AdminRoleLabels).map((value) => ({
  value,
  label: AdminRoleLabels[value as AdminUserRole],
}));

export class AdminUserModel extends BaseModel {
  orgId: string;
  name: string;
  email: string;
  phone: string;
  role: AdminUserRole;

  schema = object({
    name: string().required('Name is required'),
    email: string()
      .test('email', 'Please enter a valid email address', isValidEmail)
      .required('Please enter an email address'),
    role: string()
      .oneOf(['org:owner', 'org:admin', 'org:auditor'])
      .required('Please choose a role'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof AdminUserModel, string>;

    this.orgId = data.orgId || '';
    this.name = data.name || '';
    this.email = data.email || '';
    this.phone = data.phone || '';
    this.role = data.role ? (data.role as AdminUserRole) : 'org:auditor';
  }
}

export class CreateAdminUserModel extends CreateBaseModel {
  name: string;
  email: string;
  role: AdminUserRole;

  constructor(adminUser: AdminUserModel) {
    super();

    this.name = adminUser.name;
    this.email = String(adminUser.email).trim().toLowerCase();
    this.role = adminUser.role;
  }
}
