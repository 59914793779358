import { FC, useCallback, useMemo } from 'react';
import Page, { PageProps } from '@/components/page';
import { QueryKey } from '@/lib/query-client';
import Button from '@/components/button';
import PageHeader, { PageBlurb } from '@/components/page/header';
import Tooltip from '@/components/tooltip';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { GridCard } from '@/components/card-grid/helpers';
import { useQuery } from 'react-query';
import { createAgent, getAllAgents } from '@/lib/services/agent.service';
import AgentBadge from '@/components/agent-badge';
import { useNavigate } from '@tanstack/react-router';
import useModal from '@/hooks/use-modal.hook';
import useToast from '@/hooks/use-toast.hook';
import { AgentModel } from '@/lib/models/agent.model';
import CardGrid from '@/components/card-grid';
import { Box, Stack, Theme } from '@mui/material';
import Text from '@/components/text';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => ({
  portalBadge: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    height: 60,
  },
}));

const AgentsPage: FC<PageProps> = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { errorToast } = useToast();
  const { canChangeAgents, getTooltip } = useFeature();
  const query = useQuery([QueryKey.AgentsList], async () => getAllAgents());

  const { data: agents, refetch } = query;

  const handleRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleView = useCallback(
    (id: string) => {
      navigate({ to: '/config/assistants/$agentId', params: { agentId: id } });
    },
    [navigate]
  );

  const handleAdd = () => {
    openModal('new-with-name', {
      title: 'Create New Assistant',
      content: 'Please enter a name for the assistant',
      onSubmit: ({ name }: { name: string }) => {
        createAgent(new AgentModel({ name })).then(({ created, model, error }) => {
          closeModal();
          if (created) {
            navigate({ to: '/config/assistants/$agentId', params: { agentId: model!.id } });
            return;
          }

          errorToast(error || 'The assistant was not created');
        });
      },
    });
  };

  const agentCards: GridCard[] = useMemo(() => {
    return (agents || []).map((agent) => {
      const { id, name, description, portal } = agent;

      return {
        id: id,
        title: name,
        detail: description,
        children: (
          <Box
            flexGrow={1}
            width="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Stack direction="row" gap={1} width="100%" className={styles.portalBadge}>
              <AgentBadge agent={agent} />
              <Box minWidth="0px">
                <Text bold size="small" dotdot>
                  {portal.badge.title}
                </Text>
              </Box>
            </Stack>
          </Box>
        ),
        onClick: () => handleView(id),
      };
    });
  }, [agents, handleView, styles]);

  return (
    <Page title="Assistants" query={query}>
      <PageHeader>
        <PageBlurb>
          Create new assistants, and view and change settings for existing assistants.
        </PageBlurb>

        <Tooltip title={getTooltip('add-agent')} disabled={canChangeAgents}>
          <Button
            label="Add Assistant"
            icon="plus"
            size="small"
            onClick={() => handleAdd()}
            disabled={!canChangeAgents}
          />
        </Tooltip>
      </PageHeader>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />

      <CardGrid
        title="Assistants"
        cards={agentCards}
        showAdd={false}
        showEmpty
        onRefresh={handleRefresh}
      />
    </Page>
  );
};

export default AgentsPage;
