import { Stack } from '@mui/material';
import { FC } from 'react';
import Button from '../../button';
import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FormikValues } from 'formik';
import InlineToast from '@/components/toasts/inline';
import DrawerFooter from '@/components/drawers/footer';

interface Props {
  submit?: boolean;
  cancel?: boolean;
  isNew?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  canSubmit?: boolean;
  canCancel?: boolean;
  formHandle: FormHandle<FormikValues>;
  readonly?: boolean;
}

const DrawerButtons: FC<Props> = ({
  submit = true,
  cancel = false,
  isNew = false,
  onSubmit = () => null,
  onCancel = () => null,
  canSubmit = true,
  canCancel = true,
  formHandle,
  readonly = false,
}) => {
  const { formMessage } = formHandle;
  const hasFormMessage = formMessage?.content;

  return (
    <DrawerFooter>
      <Stack gap={3}>
        <Stack direction="row" gap={2}>
          {submit && (
            <Button
              label={isNew ? 'Save' : 'Save Changes'}
              disabled={!canSubmit || readonly}
              onClick={onSubmit}
              type="submit"
            />
          )}
          {cancel && (
            <Button label="Cancel" type="cancel" disabled={!canCancel} onClick={onCancel} />
          )}
        </Stack>
        {hasFormMessage && (
          <InlineToast
            level={formMessage.level}
            message={formMessage.content}
            attention={!!formMessage.attention}
          />
        )}
      </Stack>
    </DrawerFooter>
  );
};

export default DrawerButtons;
