import { Alert, AlertColor } from '@mui/material';
import classNames from 'classnames';
import { FC, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  level?: AlertColor;
  message: string;
  attention?: boolean;
  hideIcon?: boolean;
}

const useStyles = createUseStyles({
  inlineToast: {
    '&.attention': {
      animation: '$nudge 200ms ease-in-out 4',
    },
  },
  '@keyframes nudge': {
    '0%': {
      transform: 'translateX(0)',
    },
    '50%': {
      transform: 'translateX(-10px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
});

const InlineToast: FC<Props> = ({
  level = 'info',
  message,
  attention = false,
  hideIcon = false,
}) => {
  const styles = useStyles();
  const compClass = classNames(styles.inlineToast, { attention });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (attention && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'instant',
      });
    }
  }, [attention]);

  return (
    <Alert severity={level} className={compClass} {...(hideIcon ? { icon: false } : {})} ref={ref}>
      {message}
    </Alert>
  );
};

export default InlineToast;
