import Chip from '@/components/chip';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import useToast from '@/hooks/use-toast.hook';
import { GroupModel } from '@/lib/models/group.model';
import { PolicyModel } from '@/lib/models/policy.model';
import {
  countGroupUsers,
  createGroup,
  deleteGroup,
  getGroupById,
  groupHasDuplicateName,
  patchGroup,
} from '@/lib/services/group.service';
import { getOrgPolicy, getPoliciesByGroupId } from '@/lib/services/policy.service';
import { Box, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import Drawer, { DrawerProps } from '..';
import DrawerContent from '../content';
import DrawerHeader from '../header';
import useModal from '@/hooks/use-modal.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { getNiceNumber, pluralize, ViewState } from '@/lib/helpers';
import useQueryHelper from '@/hooks/use-query-helper';
import InputControl from '@/components/form/input-control';
import DrawerButtons from '@/components/form/drawer-buttons';
import NoneChip from '@/components/chip/none-chip';
import DrawerToolbar from '../toolbar';
import { Form } from '@/components/form';
import InlineToast from '@/components/toasts/inline';
import useFeature from '@/hooks/use-feature';
import { QueryKey } from '@/lib/query-client';
import PolicyCardList from '@/components/policy-card-list';

interface Props extends DrawerProps {
  id: string;
}

const fields: ReadonlyField[] = [
  { label: 'Name', prop: 'name' },
  { label: 'Description', prop: 'description' },
  { label: 'Source', prop: 'source' },
  { label: 'Users', prop: 'userCount' },
  { label: 'Policies', prop: 'policies-section', propType: 'section' },
];

const formModel = new GroupModel();

const getReadonlyData = (data: GroupModel | null, hasDuplicateName: boolean, userCount: number) => {
  if (!data) {
    return {};
  }

  const { name, description, sourceLabel } = data;
  const NaChipEl = <NoneChip notAvailable />;

  return {
    name: (
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={1}>
        <Box>{name}</Box>
        {hasDuplicateName && <Chip label="Duplicate Name" size="small" color="warning" />}
      </Stack>
    ),
    description: description || NaChipEl,
    source: sourceLabel,
    userCount: getNiceNumber(userCount),
  };
};

type GroupQueryResult = [GroupModel | null, number, PolicyModel[], boolean];

export const GroupDrawer: FC<Props> = ({
  id,
  open,
  onClose,
  className,
  onChange,
  hideToolbar = false,
}) => {
  const { toast, errorToast } = useToast();
  const { openModal } = useModal();
  const [groupId, setGroupId] = useState(id || null);
  const isNew = !groupId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'add' : 'view');
  const { canChangeGroups } = useFeature();

  const query = useQuery<GroupQueryResult>(
    [QueryKey.GroupMetaView, groupId],
    async () => {
      const [group, userCount, groupPolicies, orgPolicy] = await Promise.all([
        getGroupById(groupId!),
        countGroupUsers(groupId!),
        getPoliciesByGroupId(groupId!),
        getOrgPolicy(),
      ]);

      if (!group) {
        return [null, 0, [], false];
      }

      const hasDuplicateName = await groupHasDuplicateName(group.name);

      const policies = [orgPolicy!, ...(groupPolicies || [])];

      return [group, userCount, policies, hasDuplicateName];
    },
    {
      enabled: !isNew,
    }
  );

  const { showChildren } = useQueryHelper(query);
  const { data, refetch } = query;
  const [groupData, userCount = 0, policies = [], hasDuplicateName = false] = data || [];

  const group = groupData || new GroupModel();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (group, { resetForm }) => {
      if (!groupId) {
        const newGroupId = await createGroup(group);
        if (newGroupId) {
          resetForm({ values: group });
          toast('The group was created');
          setGroupId(newGroupId);
          onChange?.();
          setMode('view');
          return;
        }
        errorToast('The group was not created');
      } else {
        const { updated, error } = await patchGroup(
          group.id,
          group.propSlice(['name', 'description'])
        );
        if (updated) {
          resetForm({ values: group });
          toast('The group was updated');
          refetch();
          onChange?.();
          setMode('view');
          return;
        }
        errorToast(error || 'The group was not updated');
      }
    },
  });

  const { handleSubmit, canSubmit, isSubmitting, loadData } = formHandle;

  const editMode = ['edit', 'add'].includes(mode);

  const handleCancel = () => {
    if (isNew) {
      onClose(true);
      return;
    }

    setMode('view');
    loadData(group);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleDelete = () => {
    const hasUsers = !!userCount;

    openModal('confirm', {
      title: 'Confirm Delete',
      content: (
        <>
          Are you sure you want to delete {group.name}?
          {hasUsers && (
            <Box mt={2}>
              <InlineToast
                message={`${userCount} ${pluralize(
                  userCount,
                  'End User'
                )} will lose access to the group`}
                level="warning"
              />
            </Box>
          )}
        </>
      ),
      onClose: (confirm: boolean) => {
        if (confirm) {
          deleteGroup(groupId!).then(({ deleted, error }) => {
            if (deleted) {
              setGroupId('');
              onClose();
              toast('The group was deleted');
              onChange && onChange();
              return;
            }

            errorToast(error || 'The group was not deleted');
          });
        }
      },
    });
  };

  const canDelete = group?.canDelete;
  const canEdit = !editMode && group?.canEdit;

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>{isNew ? 'Add Group' : group?.name}</DrawerHeader>
      <DrawerContent>
        {!isNew && !hideToolbar && canChangeGroups && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={canEdit}
            canDelete={canDelete}
            deleteTooltip={!canDelete ? 'You cannot delete a group that comes from sync' : ''}
            editTooltip={!canEdit ? 'You cannot edit a group that comes from sync' : ''}
          />
        )}

        {!editMode && showChildren && (
          <>
            <ReadonlyView
              fields={fields}
              data={getReadonlyData(group, hasDuplicateName, userCount)}
            />
            <PolicyCardList policies={policies} />
          </>
        )}

        {editMode && showChildren && (
          <Form formHandle={formHandle} query={query}>
            <InputControl name="name" label="Name" formHandle={formHandle} autoFocus />

            <InputControl
              name="description"
              label="Description"
              type="textarea"
              formHandle={formHandle}
            />

            <DrawerButtons
              submit
              cancel
              isNew={isNew}
              canSubmit={canSubmit}
              canCancel={!isSubmitting}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              formHandle={formHandle}
            />
          </Form>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default GroupDrawer;
