import { cloneDeep, flatten } from 'lodash';
import { AwsConfig, AwsConnectorModel } from './aws-connector.model';
import { SharepointConfig, SharepointConnectorModel } from './sharepoint-connector.model';
import { AzureOpenAIConfig, AzureOpenAIConnectorModel } from './azure-openai-connector.model';
import { JsonObject, UiOption } from '@/lib/helpers';
import { ConnectorModel } from './connector.model';
import { WebcrawlerConfig, WebcralwerConnectorModel } from './webcrawler-connector.model';
import { NetskopeConfig, NetskopeConnectorModel } from './netskope-connector.model';
import { ZscalerConfig, ZscalerConnectorModel } from './zscaler-connector.model';
import { GeminiConfig, GeminiConnectorModel } from './gemini-connector.model';
import { OpenAIConfig, OpenAIConnectorModel } from './openai-connector.model';
import { BraveAPIConfig, BraveAPIConnectorModel } from './brave-api-connector.model';
import { ProxyConfig, ProxyConnectorModel } from './proxy-connector.model';

export type ConnectorStatus = 'inactive' | 'pending' | 'active' | 'failed';

export type ConnectorType =
  | 'aws'
  | 'sharepoint-online'
  | 'azure-openai'
  | 'webcrawler'
  | 'netskope'
  | 'zscaler'
  | 'gemini'
  | 'openai'
  | 'brave-search'
  | 'proxy';

export type ConnectorConfig =
  | AwsConfig
  | SharepointConfig
  | AzureOpenAIConfig
  | WebcrawlerConfig
  | NetskopeConfig
  | ZscalerConfig
  | GeminiConfig
  | OpenAIConfig
  | BraveAPIConfig
  | ProxyConfig;

export const ConnectorMap: Map<ConnectorType, ConnectorModel> = new Map();

ConnectorMap.set('aws', new AwsConnectorModel());
ConnectorMap.set('sharepoint-online', new SharepointConnectorModel());
ConnectorMap.set('azure-openai', new AzureOpenAIConnectorModel());
ConnectorMap.set('webcrawler', new WebcralwerConnectorModel());
ConnectorMap.set('netskope', new NetskopeConnectorModel());
ConnectorMap.set('zscaler', new ZscalerConnectorModel());
ConnectorMap.set('gemini', new GeminiConnectorModel());
ConnectorMap.set('openai', new OpenAIConnectorModel());
ConnectorMap.set('brave-search', new BraveAPIConnectorModel());
ConnectorMap.set('proxy', new ProxyConnectorModel());

export type ConnectorFeature = 'siem' | 'privateModels' | 'contextData' | 'ingress';

export type ConnectorFeatureSet = {
  siem: boolean;
  privateModels: boolean;
  contextData: boolean;
  ingress: boolean;
};

export const ConnectorFeatureLabels: Record<ConnectorFeature, string> = {
  siem: 'SIEM',
  privateModels: 'Private Models',
  contextData: 'Context Data',
  ingress: 'Ingress',
};

/*
 * Given a "generic" connector model, return a typed connector model
 * that includes all the type-specific config properties, so that
 * validation and other type-specific logic can be applied.
 * e.g. ConnectorModel -> AwsConnectorModel
 */
export const getTypedConnector = (model: ConnectorModel) => {
  const typedModel = ConnectorMap.get(model.type);

  if (!typedModel) {
    return model;
  }

  const Constructor = typedModel.constructor as new (data?: JsonObject) => typeof typedModel;

  const config = {
    ...typedModel.config,
    ...model.config,
  };

  const data = cloneDeep({ ...model._props, config });

  return new Constructor(data)!;
};

export const getConnectorOptions = (): UiOption[] => {
  return Array.from(ConnectorMap.entries()).map(([type, model]) => {
    const { typeLabel, supportedFeatures, comingSoon } = model;

    return {
      label: typeLabel,
      value: type as string,
      disabled: comingSoon,
      meta: {
        ...supportedFeatures,
      },
    };
  });
};

export const getConnectorCategoryOptions = (): UiOption[] => {
  const connectorOptions: UiOption[] = [];

  const allConnectorOptions = getConnectorOptions();

  // Ingress
  connectorOptions.push(
    {
      label: 'Network Ingress',
      value: 'ingress',
      meta: { header: true },
    },
    ...allConnectorOptions
      .filter(({ meta }) => meta?.ingress)
      .map((opt) => ({
        ...opt,
        key: `${opt.value}-ingress`,
      }))
  );

  // Private Models
  connectorOptions.push(
    {
      label: 'Private models',
      value: 'privateModels',
      meta: { header: true },
    },
    ...allConnectorOptions
      .filter(({ meta }) => meta?.privateModels)
      .map((opt) => ({
        ...opt,
        key: `${opt.value}-privateModels`,
      }))
  );

  // Context providers
  connectorOptions.push(
    {
      label: 'Data Sources',
      value: 'contextData',
      meta: { header: true },
    },
    ...allConnectorOptions
      .filter(({ meta }) => meta?.contextData)
      .map((opt) => ({
        ...opt,
        key: `${opt.value}-contextData`,
      }))
  );

  return flatten(connectorOptions);
};
