import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import FormButtons from '@/components/form/form-buttons';
import useQueryHelper from '@/hooks/use-query-helper';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { getAgentById, updateAgent } from '@/lib/services/agent.service';
import { AgentModel } from '@/lib/models/agent.model';
import { Box, Stack } from '@mui/material';
import { getAllPrivateModels } from '@/lib/services/private-model.service';
import ModelOption from '@/components/model-option';
import InlineToast from '@/components/toasts/inline';

const fields = ['systemPrompt', 'privateModelId'];
const formModel = new AgentModel();

interface Props {
  agentId: string;
}

const AgentSystemPromptForm: FC<Props> = ({ agentId }) => {
  const { errorToast, toast } = useToast();
  const { canChangeAgents } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await updateAgent(values);
      setSubmitting(false);

      if (updated) {
        resetForm({ values });
        toast('The assistant was updated');
        return;
      }

      errorToast('The assistant was not updated');
    },
  });

  const query = useQuery([QueryKey.AgentView, agentId], async () => getAgentById(agentId));
  const { showLoader: agentLoading } = useQueryHelper(query);

  const privateModelQuery = useQuery([QueryKey.PrivateModelsList], async () =>
    getAllPrivateModels()
  );
  const { data: privateModels } = privateModelQuery;
  const { showLoader: privateModelsLoading } = useQueryHelper(privateModelQuery);

  const privateModelOpts = useMemo(() => {
    return (privateModels || []).map(({ id, name, iconId }) => ({
      label: <ModelOption name={name} iconId={iconId} />,
      value: id,
    }));
  }, [privateModels]);

  if (agentLoading || privateModelsLoading) {
    return;
  }

  const hasPrivateModel = !!privateModels?.length;

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />

      <FormSection title="Private Model">
        <Box mb={2}>
          Choose the private model that this assistant will use to respond to user prompts.
        </Box>

        <Stack direction="row" gap={2}>
          <Box width="50%">
            <InputControl
              label=""
              name="privateModelId"
              type="select"
              disabled={!hasPrivateModel}
              options={privateModelOpts}
              formHandle={formHandle}
              readonly={!canChangeAgents}
            />
          </Box>

          {!privateModelsLoading && !hasPrivateModel && (
            <Box mt={2.5}>
              <InlineToast
                level="warning"
                message="Enable at least one private model to change this option"
              />
            </Box>
          )}
        </Stack>
      </FormSection>

      <FormSection title="System Prompt">
        <Box mb={2}>
          The system prompt that will be prepended to all user prompts when using this assistant.
        </Box>
        <InputControl
          name="systemPrompt"
          label="System Prompt"
          formHandle={formHandle}
          readonly={!canChangeAgents}
          type="textarea"
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangeAgents} />
    </Form>
  );
};

export default AgentSystemPromptForm;
