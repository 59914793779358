import { ChangeEvent, FC } from 'react';
import MuiPagination from '@mui/material/Pagination';
import {
  useGridApiContext,
  useGridSelector,
  GridPagination,
  gridRowCountSelector,
  gridPageSizeSelector,
} from '@mui/x-data-grid';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Box, Stack, Theme } from '@mui/material';
import Icon from '../icon';
import { DtGridApiCommunity } from '.';
import { getNiceNumber } from '@/lib/helpers';
import Loader from '../loader';
import FlatButton from '../flat-button';
import Tooltip from '../tooltip';
import { omit } from 'lodash';

const useStyles = createUseStyles((theme: Theme) => ({
  action: {
    '& .MuiPaginationItem-root.Mui-selected': {},
  },
  gridPagination: {
    '& .MuiToolbar-root': {
      paddingLeft: 30,
    },
    '& .MuiTablePagination-input': {
      marginRight: 0,
      '& .MuiTablePagination-select': {
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
}));

interface PaginationActionProps {
  page: number;
  onPageChange: (event: ChangeEvent, pageNumber: number) => void;
  className: string;
}

const PaginationAction: FC<PaginationActionProps> = ({ page, onPageChange, className = '' }) => {
  const styles = useStyles();
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const compClass = classNames(className, styles.action);

  return (
    <MuiPagination
      color="standard"
      className={compClass}
      count={Math.ceil(rowCount / pageSize)}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as ChangeEvent<Element>, newPage - 1);
      }}
    />
  );
};

interface PaginationProps {
  page: number;
  count: number;
  onExport?: () => void;
  isLoadingExport: boolean;
}

const Pagination: FC<PaginationProps> = (props) => {
  const styles = useStyles();

  const { page, count, isLoadingExport, onExport } = props;

  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const hasExport = !!onExport;

  const handleRefresh = () => {
    (apiRef.current as DtGridApiCommunity).refresh();
  };

  const start = page * pageSize + 1;
  const end = Math.min(start + pageSize - 1, rowCount);
  const hasRows = !!count;

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" flexGrow={1} gap={2}>
        <Icon color="active" name="refresh" onClick={handleRefresh} spinOnClick />

        {hasExport && (
          <>
            <Tooltip title="Download a CSV of the current view, including sort and filters">
              <FlatButton
                icon="export"
                label="EXPORT"
                onClick={onExport}
                color="link"
                size="small"
                disabled={isLoadingExport}
              />
            </Tooltip>
            {isLoadingExport && (
              <Box width="50px">
                <Loader />
              </Box>
            )}
          </>
        )}
      </Stack>
      {hasRows && (
        <>
          <Box>
            {getNiceNumber(start)} - {getNiceNumber(end)} of {getNiceNumber(rowCount)}
          </Box>
          <GridPagination
            ActionsComponent={PaginationAction}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(omit(props, ['isLoadingExport', 'onExport']) as any)}
            className={styles.gridPagination}
            labelRowsPerPage="Page Size:"
          />
        </>
      )}
    </Stack>
  );
};

export default Pagination;
