import { FC, useState } from 'react';
import Drawer, { DrawerProps } from '..';
import DrawerHeader from '../header';
import DrawerContent from '../content';
import { useQuery } from 'react-query';
import { ViewState } from '@/lib/helpers';
import useQueryHelper from '@/hooks/use-query-helper';
import useFormHandle from '@/hooks/use-form-handle.hook';
import {
  AdminRoleLabels,
  AdminRoleOptions,
  AdminUserModel,
  AdminUserRole,
} from '@/lib/models/admin-user.model';
import ReadonlyView from '../../form/readonly-view';
import InputControl from '../../form/input-control';
import useToast from '@/hooks/use-toast.hook';
import useModal from '@/hooks/use-modal.hook';
import DrawerButtons from '@/components/form/drawer-buttons';
import {
  createAdminUser,
  deleteAdminUser,
  getAdminUserById,
  patchAdminUser,
} from '@/lib/services/admin-user.service';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import DrawerToolbar from '../toolbar';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';

interface Props extends DrawerProps {
  id: string | null;
}

const fields: ReadonlyField[] = [
  { label: 'Name', prop: 'name' },
  { label: 'Email', prop: 'email' },
  { label: 'Role', prop: 'role' },
];

const formModel = new AdminUserModel();

export const AdminUserDrawer: FC<Props> = ({ id, open, onClose, onChange, className }) => {
  const [userId, setUserId] = useState(id || null);
  const isNew = !userId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'add' : 'view');

  const { toast, errorToast } = useToast();
  const { openModal } = useModal();

  const query = useQuery([QueryKey.AdminUserView, userId], async () => getAdminUserById(userId!), {
    enabled: !isNew,
  });
  const { showChildren } = useQueryHelper(query);
  const { data, refetch } = query;
  const adminUser = data || new AdminUserModel();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (user, { resetForm }) => {
      if (!userId) {
        const newUserId = await createAdminUser(user);
        if (newUserId) {
          resetForm({ values: user });
          toast('The admin user was created');
          setUserId(newUserId);
          onChange?.();
          setMode('view');
          return;
        }
        errorToast('The admin user was not created');
      } else {
        // @todo editing your own account? need to update app context, etc.

        const { updated, error } = await patchAdminUser(user);
        if (updated) {
          resetForm({ values: user });
          toast('The admin user was updated');
          refetch();
          onChange?.();
          setMode('view');
          return;
        }
        errorToast(error || 'The admin user was not updated');
      }
    },
  });

  const { handleSubmit, canSubmit, isSubmitting, loadData } = formHandle;

  const editable = ['edit', 'add'].includes(mode);

  const handleCancel = () => {
    if (isNew) {
      onClose(true);
      return;
    }
    setMode('view');
    loadData(adminUser);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleDelete = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete ${adminUser.email}?`,
      onClose: (confirm: boolean) => {
        if (confirm) {
          deleteAdminUser(userId!).then(({ deleted, error }) => {
            if (deleted) {
              setUserId('');
              onClose();
              toast('The admin user was deleted');
              onChange && onChange();
              return;
            }

            errorToast(error || 'The admin user was not deleted');
          });
        }
      },
    });
  };

  const handleReadonlyData = (data: Record<string, unknown>) => {
    return {
      ...data,
      role: AdminRoleLabels[data.role as AdminUserRole],
    };
  };

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>{isNew ? 'Add Admin User' : adminUser.name}</DrawerHeader>
      <DrawerContent>
        {!isNew && (
          <DrawerToolbar onEdit={handleEdit} onDelete={handleDelete} canEdit={!editable} />
        )}
        {!editable && showChildren && (
          <ReadonlyView fields={fields} data={adminUser._props} onData={handleReadonlyData} />
        )}

        {editable && showChildren && (
          <Form formHandle={formHandle} query={query}>
            <InputControl name="name" label="Name" formHandle={formHandle} autoFocus />

            <InputControl name="email" label="Email" formHandle={formHandle} />

            <InputControl
              name="role"
              label="Role"
              type="select"
              options={AdminRoleOptions}
              formHandle={formHandle}
            />

            <DrawerButtons
              submit
              cancel
              isNew={isNew}
              canSubmit={canSubmit}
              canCancel={!isSubmitting}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              formHandle={formHandle}
            />
          </Form>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default AdminUserDrawer;
