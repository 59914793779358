import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC, useEffect } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { ProxyConnectorModel } from '@/lib/models/connector/proxy-connector.model';

interface Props {
  connector: ProxyConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const ProxyConnector: FC<Props> = ({ formHandle, mode, connector }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);
  const isNew = !connector.id;

  const { setFieldValue } = formHandle;

  // manually update a field value so the form is marked as dirty and can be submitted
  useEffect(() => {
    if (isNew) {
      setFieldValue('config.orgAuthEnabled', true);
    }
  }, [setFieldValue, isNew]);

  if (!inWriteMode) {
    return null;
  }

  return (
    <Box>
      <InputControl
        name="config.orgAuthEnabled"
        label="Enable Organization-level Authentication"
        formHandle={formHandle}
        type="switch"
      />
    </Box>
  );
};

export default ProxyConnector;
