import { Box } from '@mui/material';
import { FC, useState } from 'react';
import DateInput from '../date-input';
import { isAfter, isBefore, isSameDay, isValid } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { FormProps } from '../helpers';
import { DateRangeValue } from '@/lib/helpers';

const useStyles = createUseStyles({
  dateRange: {
    display: 'flex',
    gap: 10,
  },
});

interface Props extends FormProps {
  endLabel?: string;
  value: DateRangeValue;
  onChange: (dates: DateRangeValue) => void;
  size?: 'small' | 'medium';
  minDate?: string | Date;
  maxDate?: string | Date;
}

const DateRangeInput: FC<Props> = ({
  name,
  label = '',
  endLabel = '',
  onChange,
  value,
  autoFocus = false,
  error,
  size = 'medium',
  disabled = false,
  minDate = '',
  maxDate = '',
}) => {
  const styles = useStyles();

  const startVal = value?.length ? value[0] : null;
  const endVal = value?.length > 1 ? value[1] : null;

  const [dates, setDates] = useState<DateRangeValue>([startVal, endVal]);
  const [rangeError, setRangeError] = useState('');

  // the problem is, if it starts not null, dates is not being initialized properly

  const handleChangeStart = (value: Date) => {
    if (isValid(value) && dates[1] && !isSameDay(value, dates[1]) && !isBefore(value, dates[1])) {
      setRangeError('The start date cannot come before the end date');
      return;
    }

    // @todo update mindate of end date

    setRangeError('');
    const updatedDates: DateRangeValue = [...dates];
    updatedDates[0] = value;
    setDates(updatedDates);
    onChange(updatedDates);
  };

  const handleChangeEnd = (value: Date) => {
    if (isValid(value) && dates[0] && !isSameDay(value, dates[0]) && !isAfter(value, dates[0])) {
      setRangeError('The end date cannot come before the start date');
      return;
    }

    // @todo BUG - when changing one date, the other date clears out

    setRangeError('');
    const updatedDates: DateRangeValue = [...dates];
    updatedDates[1] = value;
    setDates(updatedDates);
    onChange(updatedDates);
  };

  const maxStartDate = dates[1] && isValid(dates[1]) ? dates[1] : '';
  const minEndDate = dates[0] && isValid(dates[0]) ? dates[0] : '';

  return (
    <Box className={styles.dateRange}>
      <DateInput
        name={`${name}-start`}
        label={label}
        onChange={handleChangeStart}
        value={startVal}
        error={rangeError || error}
        autoFocus={autoFocus}
        maxDate={maxStartDate}
        minDate={minDate}
        disabled={disabled}
        size={size}
      />
      <DateInput
        name={`${name}-end`}
        label={endLabel}
        onChange={handleChangeEnd}
        value={endVal}
        minDate={minEndDate}
        maxDate={maxDate}
        disabled={disabled}
        size={size}
      />
    </Box>
  );
};

export default DateRangeInput;
