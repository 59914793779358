import { FC } from 'react';
import Icon from '../icon';
import { ConnectorType, ConnectorMap } from '@/lib/models/connector';
import { Box } from '@mui/material';

interface Props {
  connectorType: ConnectorType;
  size?: 'small' | 'large';
  iconClass?: string;
}

const Sizes = {
  small: { width: 30, height: 25 },
  large: { width: 60, height: 50 },
};

const ConnectorIcon: FC<Props> = ({ connectorType, size = 'small', iconClass = '' }) => {
  const { iconSVG: IconEl, icon } = ConnectorMap.get(connectorType) || {};
  const { width, height } = Sizes[size];
  const hasIcon = !!IconEl;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
      className={iconClass}
    >
      {hasIcon ? (
        <img src={IconEl} alt={connectorType} width={width} height={height} />
      ) : (
        <Icon name={icon || 'plug'} size="x-large" />
      )}
    </Box>
  );
};

export default ConnectorIcon;
