import { createContext } from 'react';

export interface UserControlSettings {
  locked: boolean;
  toggleLocked: (locked: boolean) => void;
  checkLockedAndEmit: () => boolean;
}

export const UserControlContext = createContext<UserControlSettings>({
  locked: false,
  toggleLocked: () => null,
  checkLockedAndEmit: () => false,
});
