import { useBlocker } from '@tanstack/react-router';
import { FC, ReactNode, useCallback, useState } from 'react';
import { UserControlContext } from './helpers';
import useEmitter from '@/hooks/use-emitter';

interface Props {
  children: ReactNode;
}

export const UserControlProvider: FC<Props> = ({ children }) => {
  const [locked, setLocked] = useState(false);
  const { emit } = useEmitter();

  const checkLockedAndEmit = useCallback(() => {
    if (locked) {
      emit('user-control-locked');
      return true;
    }

    return false;
  }, [locked, emit]);

  // @todo obviously this only supports a single component controlling this value at a time. in the future, this context should support
  // named locks, and be able to report which locks are locked/unlocked and whether or not all locks are unlocked/locked
  const toggleLocked = useCallback((locked: boolean) => {
    setLocked(locked);
  }, []);

  useBlocker({
    shouldBlockFn: () => {
      emit('user-control-locked');
      return true;
    },
    disabled: !locked,
  });

  return (
    <UserControlContext.Provider
      value={{
        locked,
        toggleLocked,
        checkLockedAndEmit,
      }}
    >
      {children}
    </UserControlContext.Provider>
  );
};
