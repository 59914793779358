import { JsonObject } from '../../helpers';
import { ContextProviderModel } from './context-provider.model';

export type BraveAPIConfig = {
  enabled: boolean;
};

export class BraveAPIContextProviderModel extends ContextProviderModel {
  config: BraveAPIConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ContextProviderModel, unknown>;

    this.type = 'customer-web-brave-search';
    this.typeLabel = 'Brave Search API';
    this.source = 'brave-search';

    this.config = (data.config as BraveAPIConfig) || {
      enabled: true,
    };

    this.scanable = false;
  }
}
