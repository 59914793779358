import { AppBar, Box, Tab, Tabs as MuiTabs, Theme } from '@mui/material';
import TabPanel from './tab-panel';
import { FC, ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { addUrlQueryParam, getUrlQueryParam } from '@/lib/url-helpers';
import useUserControl from '@/hooks/use-user-control.hook';

interface Props {
  labels: string[];
  panels: ReactNode[];
  disabled?: number[];
}

const useStyles = createUseStyles((theme: Theme) => ({
  tabs: {
    '& header': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[700],
      borderRadius: 5,
    },
    '& .MuiTab-root.Mui-selected': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '& div[role="tabpanel"] span.MuiLinearProgress-root': {
      margin: '30px auto 0px auto',
      width: '50%',
    },
  },
}));

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const Tabs: FC<Props> = ({ labels, panels, disabled }) => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState(Number(getUrlQueryParam('tab')) || 0);
  const { checkLockedAndEmit } = useUserControl();

  const handleChange = useCallback(
    (_event: SyntheticEvent, tabIndex: number) => {
      if (checkLockedAndEmit()) {
        return;
      }
      setTabIndex(tabIndex);
      addUrlQueryParam('tab', String(tabIndex));
    },
    [checkLockedAndEmit]
  );

  return (
    <Box className={styles.tabs}>
      <AppBar position="static" elevation={1}>
        <MuiTabs value={tabIndex} onChange={handleChange} textColor="inherit" variant="fullWidth">
          {labels.map((label, index) => (
            <Tab
              key={index}
              label={label}
              {...a11yProps(index)}
              disabled={disabled?.includes(index)}
            />
          ))}
        </MuiTabs>
      </AppBar>
      {panels.map((PanelComp, index) => (
        <TabPanel key={index} value={tabIndex} index={index}>
          {PanelComp}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabs;
