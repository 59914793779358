import { object, string } from 'yup';
import { JsonObject } from '@/lib/helpers';
import SamlIcon from '@/assets/ident-icons/saml-icon.svg';
import { SamlIdentConfig, SamlIdentProviderModel } from './saml-ident-provider.model';
import { IdentProviderModel } from './ident-provider.model';
import { IdentProviderType } from '.';

export type SamlMetadataXmlIdentConfig = SamlIdentConfig & {
  samlMetadataXML: string;
};

export class SamlMetadataXmlIdentProviderModel extends SamlIdentProviderModel {
  identityProviderConfig: SamlMetadataXmlIdentConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    this.schema = new IdentProviderModel().schema.concat(
      object({
        identityProviderConfig: object({
          samlMetadataXML: string().required('Metadata XML is required'),
        }),
      })
    );

    const data = props as Record<keyof SamlMetadataXmlIdentProviderModel, unknown>;

    this.type = 'SAML-XML';
    this.typeLabel = 'SAML - Metadata XML';
    this.iconSVG = SamlIcon;

    this.identityProviderConfig = (data.identityProviderConfig as SamlMetadataXmlIdentConfig) || {
      samlMetadataXML: '',
    };
  }

  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get createType(): IdentProviderType {
    return 'SAML';
  }
}
