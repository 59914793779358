import { FC, useState } from 'react';
import Button from '@/components/button';
import Menu from '@/components/menu';
import { Box, Stack } from '@mui/material';
import {
  PublicCatalogDownloadFormat,
  PublicCatalogDownloadOpts,
  downloadCatalog,
} from '@/lib/services/public-service.service';
import Select from '@/components/form/select';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { QueryKey } from '@/lib/query-client';
import useApp from '@/hooks/use-app.hook';

const DownloadMenu: FC = () => {
  const [downloadFormat, setDownloadFormat] = useState<PublicCatalogDownloadFormat | ''>('');
  const [enabled, setEnabled] = useState(false);
  const { user } = useApp();

  const query = useQuery(
    [QueryKey.PublicServicesDownload],
    async () => {
      if (!downloadFormat) {
        return;
      }

      await downloadCatalog(downloadFormat, user?.orgId);
    },
    { enabled }
  );

  const { refetch } = query;
  const { showLoader } = useQueryHelper(query);

  const handleDownload = () => {
    if (!downloadFormat) {
      return;
    }

    if (!enabled) {
      setEnabled(true);
      return;
    }

    refetch();
  };

  const TriggerEl = <Button label="Download Catalog" icon="download" size="small" />;
  const canDownload = Boolean(downloadFormat);
  const handleChange = (val: string) => {
    setDownloadFormat(val as PublicCatalogDownloadFormat);
  };

  return (
    <>
      <Menu title="Download Public Services Catalog" menuId="filters" trigger={TriggerEl}>
        <Stack gap={3} width={400}>
          <Box>Choose the file format:</Box>
          <Select
            label="Download Format"
            value={downloadFormat}
            name="download-format"
            options={PublicCatalogDownloadOpts}
            onChange={handleChange}
          />
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button
              label="Download"
              disabled={!canDownload || showLoader}
              onClick={handleDownload}
            />
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default DownloadMenu;
